let input = {
	baseStyle: {
		field: {
			border: 'none',
			bg: 'white',
			_placeholder: {
				color: 'blackAlpha.500',
				fontWeight: 600,
				fontSize: '1rem',
			},
		},
	},
	variants: {
		outline: {
			field: {
				bg: 'white',
				border: '2px solid',
				borderColor: 'blackAlpha.300',
			},
		},
		muted: {
			field: {
				bg: 'gray.100',
				color: 'gray.900',
			},
		},
		search: {
			field: {
				border: `2px solid`,
				borderColor: `blackAlpha.300`,
				fontWeight: '400',
			},
		},
		flushed: {
			field: {
				borderBottom: `2px solid`,
				borderColor: `blackAlpha.300`,
			},
		},
	},
	sizes: {
		xl: {
			field: { fontSize: 'lg', px: 5, h: 12, borderRadius: 'lg' },
			addon: { fontSize: 'lg', px: 5, h: 12, borderRadius: 'lg' },
		},
	},
};

export default input;
