let checkbox = {
	baseStyle: {
		control: {
			my: 1,
		},
	},
	sizes: {
		xl: {
			control: {
				w: 6,
				h: 6,
			},
			label: {
				fontSize: 'xl',
			},
			icon: {
				fontSize: '1rem',
			},
		},
		'xl-labelMd': {
			control: {
				w: 6,
				h: 6,
			},
			label: {
				fontSize: 'md',
			},
			icon: {
				fontSize: '1rem',
			},
		},
	},
};

export default checkbox;
