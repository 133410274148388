import menuRoutes from '../utils/menuRoutes';
import { useMemo } from 'react';
import IMenuRoutes from '../types/interfaces/IMenuRoutes';

export default function useMenu(menu: string): IMenuRoutes[] {
	const menuArray = useMemo(generateMenu, [menu]);

	function generateMenu() {
		return menuRoutes[menu];
	}

	return menuArray;
}
