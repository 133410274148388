import React, { createContext, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import socket from '../../services/socket';
import IBaseProps from '../../types/interfaces/IBaseProps';

export const SocketContext = createContext<
	| {
			value: {
				socket: SocketIOClient.Socket;
				channels: string[];
				setChannels: React.Dispatch<React.SetStateAction<string[]>>;
				connectedChannels: string[];
				setConnectedChannels: React.Dispatch<
					React.SetStateAction<string[]>
				>;
				isConnecting: boolean;
				setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>;
			};
	  }
	| undefined
>(undefined);

export default function LayoutBase(props: IBaseProps) {
	const { isAuth } = useAuth();
	const [channels, setChannels] = useState<string[]>(['']);
	const [connectedChannels, setConnectedChannels] = useState<string[]>([]);
	const [isConnecting, setIsConnecting] = useState<boolean>(false);

	useEffect(() => {
		if (isAuth) {
			socket.connect();
		}
	}, [isAuth]);

	return (
		<SocketContext.Provider
			value={{
				value: {
					socket,
					channels,
					setChannels,
					connectedChannels,
					setConnectedChannels,
					isConnecting,
					setIsConnecting,
				},
			}}
		>
			{props.children}
		</SocketContext.Provider>
	);
}
