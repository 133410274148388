/**
 * Config axios file
 */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class Axios {
	private _backend: string;
	private _axios: AxiosInstance;

	constructor(backend: string, options?: AxiosRequestConfig) {
		this._backend = backend;
		this._axios = this.createAxios(options);
	}

	private createAxios(options?: AxiosRequestConfig): AxiosInstance {
		let axiosCreate = axios.create({
			baseURL: this.backend,
			headers: { Accept: 'application/json' },
			...options,
		});
		return axiosCreate;
	}

	public get backend(): string {
		return this._backend?.charAt(this._backend.length - 1) === '/'
			? this._backend.slice(0, -1)
			: this._backend;
	}

	public async get(
		path: string,
		data?: object,
		options?: AxiosRequestConfig,
	): Promise<any> {
		let response;
		try {
			response = await this._axios.get(path, {
				params: data,
				...options,
			});
			return response.data;
		} catch (err) {
			return this.handleErrors(err);
		}
	}

	public async post(
		path: string,
		data?: any,
		options?: AxiosRequestConfig,
	): Promise<any> {
		let response;
		try {
			response = await this._axios.post(path, data, {
				...options,
			});
			return response.data;
		} catch (err) {
			return this.handleErrors(err);
		}
	}

	private handleErrors(error: any): object {
		if (error.response) {
			throw new Error(error.response.data);
		} else if (error.request) {
			throw new Error(error.request);
		} else throw new Error(error.message);
	}
}
