import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import socket from '../services/socket';
import API from '../utils/api';

export default function useLogout() {
	const router = useRouter();
	const queryClient = useQueryClient();
	async function logout(redirectHome: boolean = true) {
		await API.User.logout(async () => {
			socket.disconnect();
			await queryClient.invalidateQueries('user');
			await queryClient.invalidateQueries('session');
			mixpanel.reset();
			localStorage.removeItem('adminMode');
		});

		if (redirectHome) {
			router.push('/');
		}
	}
	return { logout };
}
