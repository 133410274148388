import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Link, Text, useMediaQuery } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { FaBox, FaComment, FaHeart, FaUser } from 'react-icons/fa';
import { useQuery } from 'react-query';
import useAuth from '../../hooks/useAuth';
import useSocket from '../../hooks/useSocket';
import useSocketEvent from '../../hooks/useSocketEvent';
import { IMessage } from '../../services/api/chat/types/IResponse';
import Absolute from '../../ui/Absolute';
import API from '../../utils/api';

interface IProps {}

function BottomMobileBar(props: IProps) {
	const heightBar = '70px';
	const [isLg] = useMediaQuery('(min-width: 868px)');
	const socket = useSocket();
	const { isAuth, user } = useAuth();
	const chatNotify = useSocketEvent<IMessage>({
		socket: socket.socket,
		eventName: 'chatNotify',
	});
	const chatRead = useSocketEvent<IMessage>({
		socket: socket.socket,
		eventName: 'chatRead',
	});
	const [countNewMessages, setCountNewMessages] = useState<number>(0);

	const messagesNotRead = useQuery(
		[
			'messages',
			'not-read',
			user?.id,
			chatRead.eventData?.id,
			chatNotify.eventData?.id,
		],
		async () =>
			await API.Chat.list(
				{ cursor: 1, limit: 1 },
				{ receiverId: user!.id, read: false },
			),
		{ enabled: !!user },
	);

	useEffect(() => {
		if (messagesNotRead.isSuccess) {
			setCountNewMessages(
				messagesNotRead.data
					? (messagesNotRead.data.items.paginationMeta?.nbResults ||
							0) -
							messagesNotRead.data.items.filter(
								mex => mex.metadata.openChat,
							).length
					: 0,
			);
		}
	}, [messagesNotRead]);

	return (
		<Flex
			pos={'fixed'}
			bottom={0}
			w={'100%'}
			minH={heightBar}
			bgColor={'white'}
			zIndex={99999997}
			display={{ base: undefined, md: 'none' }}
			align={'center'}
			borderTop={'1px'}
			borderColor={'blackAlpha.200'}
			boxShadow={'0 7px 15px rgba(0, 0, 0, 0.2);'}
		>
			<Flex
				h={heightBar}
				px={8}
				w={'100%'}
				justify={'space-between'}
				align={'center'}
			>
				<Box w={'45px'}>
					<NextLink href={'/account/orders?back=false'} passHref>
						<Link
							color={'gray.500'}
							opacity={
								window.location.pathname.includes(
									'account/orders',
								)
									? 1
									: 0.7
							}
						>
							<Flex
								flexFlow={'column'}
								justify={'center'}
								align={'center'}
							>
								<Icon
									verticalAlign={'middle'}
									as={FaBox}
									boxSize={5}
								></Icon>
								<Text
									fontWeight={800}
									lineHeight={1}
									fontSize={'11px'}
									mt={2}
								>
									Ordini
								</Text>
							</Flex>
						</Link>
					</NextLink>
				</Box>
				<Box w={'45px'}>
					<NextLink href={'/wishlist'} passHref>
						<Link
							color={'brandOne.500'}
							opacity={
								window.location.pathname.includes('wishlist')
									? 1
									: 0.7
							}
						>
							<Flex
								flexFlow={'column'}
								justify={'center'}
								align={'center'}
							>
								<Icon
									verticalAlign={'middle'}
									as={FaHeart}
									boxSize={5}
								></Icon>
								<Text
									fontWeight={800}
									lineHeight={1}
									fontSize={'11px'}
									mt={2}
								>
									Wishlist
								</Text>
							</Flex>
						</Link>
					</NextLink>
				</Box>
				<Box w={'45px'}>
					<NextLink href={'/product/new'} passHref>
						<Link
							pos={'relative'}
							h={'100%'}
							opacity={
								window.location.pathname.includes('product/new')
									? 1
									: 0.7
							}
						>
							<Flex
								flexFlow={'column'}
								justify={'center'}
								align={'center'}
							>
								<Flex
									bg={'brandGradient.500'}
									borderRadius={'full'}
									boxSize={5}
									justify={'center'}
									align={'center'}
								>
									<AddIcon boxSize={'10px'} color="white" />
								</Flex>

								<Text
									fontWeight={800}
									lineHeight={1}
									fontSize={'11px'}
									mt={2}
									bg={'brandGradient.500'}
									bgClip={'text'}
									fill={'transparent'}
								>
									Nuovo
								</Text>
							</Flex>
						</Link>
					</NextLink>
				</Box>
				<Box w={'45px'}>
					<NextLink
						href={
							typeof window !== 'undefined' &&
							window.location.href.includes('chat') &&
							isLg
								? '#'
								: '/chat'
						}
						passHref
					>
						<Link
							color={'brandTwo.500'}
							opacity={
								window.location.pathname.includes('chat')
									? 1
									: 0.7
							}
							pos={'relative'}
							h={'100%'}
						>
							<Flex
								flexFlow={'column'}
								justify={'center'}
								align={'center'}
							>
								<Icon
									verticalAlign={'middle'}
									as={FaComment}
									boxSize={5}
								></Icon>
								{countNewMessages > 0 && (
									<Absolute right={0} left={'60%'} h={'100%'}>
										<Flex
											borderRadius={'full'}
											bgColor={'red'}
											h={'15px'}
											w={'fit-content'}
											minW={'10px'}
											maxW={'20px'}
											maxH={'10px'}
											justify={'center'}
											align={'center'}
											fontSize={'xs'}
											fontWeight={700}
											color={'white'}
											lineHeight={0}
											px={1}
										>
											{/* {countNewMessages} */}
										</Flex>
									</Absolute>
								)}
								<Text
									fontWeight={800}
									lineHeight={1}
									fontSize={'11px'}
									mt={2}
								>
									Chat
								</Text>
							</Flex>
						</Link>
					</NextLink>
				</Box>
				<Box w={'45px'}>
					<NextLink href={'/account'} passHref>
						<Link
							color={'blackAlpha.700'}
							opacity={
								window.location.pathname.includes('account') &&
								!window.location.pathname.includes('orders')
									? 1
									: 0.7
							}
						>
							<Flex
								flexFlow={'column'}
								justify={'center'}
								align={'center'}
							>
								<Icon
									verticalAlign={'middle'}
									as={FaUser}
									boxSize={5}
								></Icon>
								<Text
									fontWeight={800}
									lineHeight={1}
									fontSize={'11px'}
									mt={2}
								>
									Utente
								</Text>
							</Flex>
						</Link>
					</NextLink>
				</Box>
			</Flex>
		</Flex>
	);
}

export default BottomMobileBar;
