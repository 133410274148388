import { useEffect, useState } from 'react';
import socket from '../services/socket/index';

interface IProps {
	socket: SocketIOClient.Socket;
	eventName?: string;
}

export default function useSocketEvent<T = any>(props?: IProps) {
	const [eventData, setEventData] = useState<T | undefined>(undefined);

	useEffect(() => {
		
		if (props?.eventName && props.socket) {
			props.socket.on(props.eventName, (data: { message: T }) => {
				
				setEventData(data.message);
			});
		}

		return () => {
			if (props?.eventName) socket.off(props?.eventName);
		};
	}, [props?.eventName, props?.socket]);

	useEffect(() => {
		return () => {
			if (props?.eventName) socket.off(props?.eventName);
		};
	}, []);

	return { eventData };
}
