import IMenuRoutes from '../types/interfaces/IMenuRoutes';

interface Menu {
	[menuName: string]: IMenuRoutes[];
}

function headerMenu(): IMenuRoutes[] {
	let voices = [
		{
			name: 'Abbigliamento',
			id: 'ctgy_AY5dRe1ltt1pP7IUltt',
			children: [
				{
					id: 'ctgy_OCx72e1NCn1p0Q64NCn',
					name: 'Per neonati',
					children: [
						{
							id: 'ctgy_J6HCte1G301otGK1G30',
							name: 'Body e tutine',
						},
						{
							id: 'ctgy_DCn4te1lni1pP14Dlni',
							name: 'Cappotti, giubbotti e tutine pilota',
						},
						{
							id: 'ctgy_B5OWHe1hQr1pKeB8hQr',
							name: 'Maglioni e felpe',
						},
						{
							id: 'ctgy_HjAk0e1Lxv1ozBH3Lxv',
							name: 'Pantaloni e leggings',
						},
						{ id: 'ctgy_n30URe1HXk1oul6MHXk', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_666ZWe1kjc1pNxApkjc',
					name: 'Accessori',
					children: [
						{
							id: 'ctgy_0LsWue1IzI1owCr2IzI',
							name: 'Borse e zaini',
						},
						{ id: 'ctgy_YEMYSe1CFx1opTWKCFx', name: 'Calze' },
						{
							id: 'ctgy_7D90He1luw1pP8V8luw',
							name: 'Cappelli e berretti',
						},
						{
							id: 'ctgy_I8NiMe1TB61prukjTB6',
							name: 'Occhiali',
						},
						{
							id: 'ctgy_dFPS5e1CZX1opn6FCZX',
							name: 'Sciarpe e guanti',
						},
						{ id: 'ctgy_mflNme1jjA1pMwjQjjA', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_Bu1UTe1Vj11p8wRKVj1',
					name: 'Cappotti e giacche',
					children: [
						{
							id: 'ctgy_K315Pe15Cq1oiQGY5Cq',
							name: 'Cappotti e parka',
						},
						{ id: 'ctgy_O4Z00e1kBP1pNOpmkBP', name: 'Giacche' },
						{
							id: 'ctgy_YF0PIe1Tul1p78BuTul',
							name: 'Impermeabili',
						},
						{
							id: 'ctgy_BTUl7e1ZaZ1pCnzgZaZ',
							name: 'Piumini e giubbotti',
						},
						{ id: 'ctgy_H6V3Te1D0s1oqEJ7D0s', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_ptGRye1wyP1paBvWwyP',
					name: 'Costumi da bagno',
					children: [
						{ id: 'ctgy_pq79Le1xzM1pbCspxzM', name: 'Accappatoi' },

						{ id: 'ctgy_j9s3Se1IG01ovTWhIG0', name: 'Costumi' },
						{ id: 'ctgy_c0A3Ae159l1oiNI659l', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_QYb6Pe1quk1pU8C6quk',
					name: 'Maglioni e felpe',
					children: [
						{ id: 'ctgy_QTdFVe1lzl1pPDDUlzl', name: 'Cardigan' },
						{ id: 'ctgy_Wl5P2e1rDI1pUQkirDI', name: 'Felpe' },
						{ id: 'ctgy_jke2je1yGc1pbU4pyGc', name: 'Gilet' },
						{ id: 'ctgy_j8A3ne1LuS1oz7ubLuS', name: 'Maglioni' },
						{ id: 'ctgy_zlIvKe1HWf1ouk7xHWf', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_0674Re11Fi1oeTC71Fi',
					name: 'Pantaloni e leggings',
					children: [
						{ id: 'ctgy_1oGsFe1rgI1pUtmorgI', name: 'Jeans' },
						{
							id: 'ctgy_exBRSe1sgC1pVtgfsgC',
							name: 'Leggings e jogger',
						},
						{ id: 'ctgy_lWk9Ze1d4y1pGISJd4y', name: 'Pantaloni' },
						{
							id: 'ctgy_G0X1Me1WIF1p9Vk2WIF',
							name: 'Pantaloni corti',
						},
						{ id: 'ctgy_KRuDBe1cPE1pFciucPE', name: 'Salopette' },
						{ id: 'ctgy_Is4rBe1LSK1oyfpALSK', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_EF37qe1V3n1p8HIkV3n',
					name: 'Pigiami',
					children: [
						{
							id: 'ctgy_nTWKBe1p7p1pSLL5p7p',
							name: 'Pigiami interi',
						},
						{
							id: 'ctgy_pd2v0e10g01odtWA0g0',
							name: 'Pigiami spezzati',
						},
						{ id: 'ctgy_5siTze1QWM1p3jsHQWM', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_Ji0h8e1GyL1ouBsKGyL',
					name: 'Scarpe',
					children: [
						{
							id: 'ctgy_DyM20e1zF51pcSd0zF5',
							name: 'Pantofole e ciabatte',
						},
						{
							id: 'ctgy_4SOWCe1KrN1oy4uvKrN',
							name: 'Scarpe estive',
						},
						{
							id: 'ctgy_gJSH8e1vHF1pYUmqvHF',
							name: 'Scarpe invernali',
						},
						{ id: 'ctgy_w5mITe1g0v1pJESZg0v', name: 'Sneakers' },
						{
							id: 'ctgy_aPOk9e1IBx1ovPUgIBx',
							name: 'Stivali e stivaletti',
						},
						{ id: 'ctgy_rJaBDe1jHF1pMUnBjHF', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_7OCePe14hH1ohukI4hH',
					name: 'T-Shirt e camicie',
					children: [
						{ id: 'ctgy_EOocEe19RA1omedT9RA', name: 'Camicie' },
						{ id: 'ctgy_nXNuGe1iEk1pLSDliEk', name: 'Polo' },
						{ id: 'ctgy_YRlzce1tcM1pWppdtcM', name: 'T-Shirt' },
						{ id: 'ctgy_KDNTve15Tc1oih5t5Tc', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_7n9TrE11rx1qjnzQ1rx',
					name: 'Abiti',
				},
			],
		},
		{
			name: 'Giochi',
			id: 'ctgy_N00afe1c1v1pFFerc1v',
			children: [
				{
					id: 'ctgy_4XG2Fe1NaM1p0o9jNaM',
					name: 'Bici e monopattini',
				},
				{
					id: 'ctgy_hUiHje1fCH1pIQ1TfCH',
					name: 'Giocattoli',
					children: [
						{ id: 'ctgy_dhJRxe1jwF1pN9zzjwF', name: 'Bambole' },
						{ id: 'ctgy_bF9M0e1tPx1pWdi9tPx', name: 'Circuiti' },
						{
							id: 'ctgy_5dYNFe1N3p1p0Ha4N3p',
							name: 'Cucina e mercatini',
						},
						{
							id: 'ctgy_2lxXDe1c7s1pFLcqc7s',
							name: 'Giochi educativi',
						},
						{ id: 'ctgy_fbLHPe1EGs1orUcuEGs', name: 'Musica' },
						{ id: 'ctgy_4zDcke18Zp1olnZe8Zp', name: 'Peluche' },
						{
							id: 'ctgy_7B80Ie1VXk1p8lUkVXk',
							name: 'Primi giochi',
						},
						{ id: 'ctgy_CxMrte14Qo1oheZF4Qo', name: 'Statuette' },
						{
							id: 'ctgy_WQHQde1C901opMlKC90',
							name: 'Tappetini da gioco',
						},
						{ id: 'ctgy_hsFQne1neW1pQsHQneW', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_HMEiye1GAZ1otOMVGAZ',
					name: 'Giochi da tavolo',
				},
				{
					id: 'ctgy_JBCzHe1lNp1pObcIlNp',
					name: 'Giochi elettronici',
				},
				{
					id: 'ctgy_Ix0lOe1oQs1pReezoQs',
					name: 'Libri',
				},
				{
					id: 'ctgy_nopBIe1hxf1pLBQuhxf',
					name: 'Passatempi',
					children: [
						{ id: 'ctgy_GmDrqe1t6Z1pWKLRt6Z', name: 'Altalene' },

						{
							id: 'ctgy_X1xw4e1cah1pFoTWcah',
							name: 'Bici e tricicli',
						},
						{
							id: 'ctgy_U8iwVe1Ak21onxoEAk2',
							name: 'Giochi a dondolo',
						},
						{
							id: 'ctgy_baJ6ye1Fb91osovcFb9',
							name: 'Giochi da esterno',
						},
						{ id: 'ctgy_KuPYQe170a1okEM970a', name: 'Girelli' },
						{
							id: 'ctgy_uprZje1Y291pBFvjY29',
							name: 'Tende e casette',
						},
						{ id: 'ctgy_OiqcEe1L3q1oyHcpL3q', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_mnME1e1Ki21oxvpPKi2',
					name: 'Rompicapo e puzzle',
				},
				{
					id: 'ctgy_MNCs6e1nAe1pQORpnAe',
					name: 'Skate e pattini',
				},
				{
					id: 'ctgy_kj8Vqe10D01odQne0D0',
					name: 'Video e audio',
				},
			],
		},
		{
			name: 'Casa',
			id: 'ctgy_lo9SBe1OcR1p1q0pOcR',
			children: [
				{
					id: 'ctgy_EX9Z3e1bWR1pEk3fbWR',
					name: 'Cameretta',
					children: [
						{
							id: 'ctgy_prDq1e1dWq1pGkTPdWq',
							name: 'Biancheria letto',
						},
						{
							id: 'ctgy_QXSOLe19gS1omu5a9gS',
							name: 'Cassettiere e armadi',
						},
						{
							id: 'ctgy_OHN9Ie1P4S1p2I67P4S',
							name: 'Ceste e contenitori porta giochi',
						},
						{
							id: 'ctgy_5K81Ye1CsH1oq5uTCsH',
							name: 'Coperte e piumini',
						},
						{
							id: 'ctgy_rnWPQe1kRU1pNf7KkRU',
							name: 'Cuscini e materassi',
						},
						{ id: 'ctgy_JO3SMe1US71p7flNUS7', name: 'Lampade' },
						{ id: 'ctgy_jZZDle190o1omERE90o', name: 'Letti' },
						{
							id: 'ctgy_4hFcZe1xHG1paUtgxHG',
							name: 'Librerie e mensole',
						},
						{
							id: 'ctgy_vGSsWe1RJO1p4X2GRJO',
							name: 'Oggetti decorativi',
						},
						{ id: 'ctgy_Bigjme10Ck1odQNw0Ck', name: 'Poufs' },
						{
							id: 'ctgy_mPatde1A5q1onJTrA5q',
							name: 'Sedie e sgabelli',
						},
						{ id: 'ctgy_bhQhKe1Z721pCKg3Z72', name: 'Tappeti' },
						{
							id: 'ctgy_Zstv9e1Aey1onsbmAey',
							name: 'Tavoli e scrivanie',
						},
						{ id: 'ctgy_Euwije1Ouj1p28NTOuj', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_SrMEge1zgI1pctsAzgI',
					name: 'Tutto per il neonato',
					children: [
						{
							id: 'ctgy_cXwh5e1aoe1pE2Eaaoe',
							name: 'Biancheria letto',
						},
						{ id: 'ctgy_NyKLMe1PvC1p38nzPvC', name: 'Box' },
						{
							id: 'ctgy_fPH20e1ZvP1pD90NZvP',
							name: 'Cassettiere e armadi',
						},
						{
							id: 'ctgy_MkrTee1Hsc1ov6DZHsc',
							name: 'Ceste e contenitori porta giochi',
						},
						{ id: 'ctgy_qQ5RQe1xXw1palWfxXw', name: 'Coperte' },
						{
							id: 'ctgy_ad7u0e1BV41ooieSBV4',
							name: 'Cuscini e materassi',
						},
						{ id: 'ctgy_iMoZIe1geu1pJsVFgeu', name: 'Fasciatoi' },
						{
							id: 'ctgy_dBS5ne1uA01pXNbAuA0',
							name: 'Giostrine e lampade da notte',
						},
						{
							id: 'ctgy_bk9dke1u571pXIhNu57',
							name: 'Lettini e culle',
						},
						{
							id: 'ctgy_u5p7qe1b051pEDg3b05',
							name: 'Sacchi nanna e paracolpi',
						},
						{
							id: 'ctgy_Jytp0e1VoG1p91rhVoG',
							name: 'Sdraiette e altalene',
						},
						{ id: 'ctgy_r0j0we1UTG1p7grpUTG', name: 'Seggioloni' },
						{
							id: 'ctgy_xN5y9e1QeB1p3rnEQeB',
							name: 'Sicurezza in casa',
						},
						{
							id: 'ctgy_tmZ5Pe1f4d1pIIF7f4d',
							name: 'Tappetini educativi',
						},
						{ id: 'ctgy_1ZeMIe1CDD1opQpMCDD', name: 'Altro' },
					],
				},
			],
		},
		{
			name: 'Viaggio',
			id: 'ctgy_aYSY2e1lGk1pOUOblGk',
			children: [
				{
					id: 'ctgy_rj6k0e1o7J1pRKy8o7J',
					name: 'A spasso',
					children: [
						{
							id: 'ctgy_4GqxNe15ka1oiyFN5ka',
							name: 'Accessori e borse passegini',
						},

						{
							id: 'ctgy_AlwWUe1g0u1pJEZtg0u',
							name: 'Marsupi e fasce',
						},
						{ id: 'ctgy_2yCvZe1CpM1oq31eCpM', name: 'Navicelle' },
						{ id: 'ctgy_27w0ye1QDm1p3RRGQDm', name: 'Passeggini' },
						{
							id: 'ctgy_LocnJe1KbX1oxpCmKbX',
							name: 'Sistemi duo e trio',
						},
						{ id: 'ctgy_lcmlme1KdL1oxr0yKdL', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_9jiSfe1CTU1ophAOCTU',
					name: 'Viaggio',
					children: [
						{
							id: 'ctgy_R5nnGe11Qr1oeeYd1Qr',
							name: 'Accessori seggiolini',
						},
						{
							id: 'ctgy_tJD0Xe1f401pIHgrf40',
							name: 'Accessori viaggio',
						},

						{
							id: 'ctgy_38ydPe1hTx1pKhemhTx',
							name: 'Dispositivi di sicurezza',
						},
						{
							id: 'ctgy_rIvfie1dIY1pGWF2dIY',
							name: 'Fasciatoio da viaggio',
						},
						{
							id: 'ctgy_jpo6xe1VpX1p93DcVpX',
							name: 'Lettini da viaggio',
						},
						{
							id: 'ctgy_zI0Hce1X0K1pAE1VX0K',
							name: 'Seggiolini auto',
						},
						{ id: 'ctgy_ZbvdVe19lS1omz9P9lS', name: 'Trousse' },
						{
							id: 'ctgy_uVVA2e1zE51pcRmBzE5',
							name: 'Valigie e trolley',
						},
						{
							id: 'ctgy_xgAE7e1StC1p66tIStC',
							name: 'Zaini e sacche',
						},
						{ id: 'ctgy_thj2Ke1mvt1pQ9asmvt', name: 'Altro' },
					],
				},
			],
		},
		{
			name: 'Allattamento e pappa',
			id: 'ctgy_fYW3He1XTI1pAh66XTI',
			children: [
				{
					id: 'ctgy_1DuYse1fAt1pIOhPfAt',
					name: 'Allattamento',
					children: [
						{ id: 'ctgy_VR5YIe1R0t1p4EhdR0t', name: 'Bavaglini' },
						{
							id: 'ctgy_ELLzce1Rd01p4qorRd0',
							name: 'Biberon e tettarelle',
						},
						{
							id: 'ctgy_jjcGge1Jzw1oxDkvJzw',
							name: 'Ciucci e massaggiagengive',
						},
						{
							id: 'ctgy_dX7mwe1Saw1p5ol0Saw',
							name: 'Coppette e cura seno',
						},
						{
							id: 'ctgy_tVByVe1MUF1ozi47MUF',
							name: 'Cuscini allattamento',
						},
						{
							id: 'ctgy_BUlAje1kCY1pNQOakCY',
							name: 'Preparabiberon',
						},
						{
							id: 'ctgy_uF7p7e18Yp1olmfW8Yp',
							name: 'Scaldabiberon',
						},
						{
							id: 'ctgy_uUyPNe1oYo1pRmefoYo',
							name: 'Sterilizzatore',
						},
						{ id: 'ctgy_viQVve1M1J1ozF9kM1J', name: 'Tiralatte' },
						{ id: 'ctgy_9d2F0e1Bln1oozdpBln', name: 'Altro' },
					],
				},
				{
					id: 'ctgy_V5N9ae1feE1pIs5TfeE',
					name: 'Prime pappe',
					children: [
						{ id: 'ctgy_Idk8le1Lqb1oz4SfLqb', name: 'Bavaglini' },
						{ id: 'ctgy_eoUUve15Qt1oieku5Qt', name: 'Bicchieri' },
						{ id: 'ctgy_YYnYLe1lyn1pPCfDlyn', name: 'Borracce' },
						{ id: 'ctgy_fnqEXe1jeZ1pMsRJjeZ', name: 'Borse' },
						{
							id: 'ctgy_h6XSMe18p01om2s78p0',
							name: 'Contenitori cibo e portapappa',
						},
						{ id: 'ctgy_9PgFse1G0c1otEUSG0c', name: 'Frullatori' },
						{
							id: 'ctgy_7Ls9Ke1Sgy1p5upkSgy',
							name: 'Piatti e ciotole',
						},
						{ id: 'ctgy_UHXaue1lyo1pPCfolyo', name: 'Posate' },
						{ id: 'ctgy_7kf40e1oJk1pRXcdoJk', name: 'Scaldapappa' },
						{ id: 'ctgy_4uOfRe1Fqf1ot4X1Fqf', name: 'Tovagliette' },
						{ id: 'ctgy_BGctQe1owM1pSAEoowM', name: 'Altro' },
					],
				},
			],
		},
		{
			name: 'Igiene e cura',
			id: 'ctgy_n0pBBe1T2L1p6G38T2L',
			children: [
				{
					id: 'ctgy_6dKqbe1L8B1oyLtvL8B',
					name: 'Accappatoio e asciugamani',
				},

				{
					id: 'ctgy_1YU8Ee1SKs1p5YbNSKs',
					name: 'Bilancia',
				},
				{
					id: 'ctgy_bFlqCe1aga1pDuJDaga',
					name: 'Cura del bambino',
				},
				{
					id: 'ctgy_Fennte1hWR1pKkAIhWR',
					name: 'Set bagno',
				},
				{
					id: 'ctgy_kTj3ce1AiC1onvvUAiC',
					name: 'Termometro',
				},
				{
					id: 'ctgy_7IGjme1sHV1pVVE2sHV',
					name: 'Vaschette e accessori',
				},
				{
					id: 'ctgy_Gq5lpe1vHE1pYUx6vHE',
					name: 'Vasini e pedane',
				},
				{
					id: 'ctgy_3MEg6e1e7N1pHL6ce7N',
					name: 'Altro',
				},
			],
		},
		{
			name: 'Asilo e scuola',
			id: 'ctgy_0dPF0e1cT01pFgsvcT0',
			children: [
				{
					id: 'ctgy_nBuz8e1meE1qGMXXmeE',
					name: 'Cartoleria',
				},
				{
					id: 'ctgy_6TIqre1QJX1pu1rFQJX',
					name: 'Zaini e sacchi',
				},
				{
					id: 'ctgy_JVab7e1Ki71poQS0Ki7',
					name: 'Grembiuli',
				},
				{
					id: 'ctgy_xbL59e1sUd1qMCy5sUd',
					name: 'Altro',
				},
			],
		},
		{
			name: 'Altri articoli',
			id: 'ctgy_OZwaTe1mSK1pPgDMmSK',
		},
	];

	return voices.map(voice => {
		return {
			name: voice.name,
			children: voice.children?.map(voiceChildren => {
				return {
					name: voiceChildren.name,
					path: `/category/${voiceChildren.id}`,
					children: voiceChildren.children?.map(voiceChildren2 => {
						return {
							name: voiceChildren2.name,
							path: `/category/${voiceChildren2.id}`,
						};
					}),
				};
			}),
			path: `/category/${voice.id}`,
		};
	});
}

const menu: Menu = {
	footer: [
		{ name: 'Su di noi', path: '/about-us' },
		{ name: 'Come funziona', path: '/help/guide' },
		{ name: 'Spedizione', path: '/help/guide#l-4B' },
		{ name: 'Sicurezza e affidabilità', path: '/lp/security' },
		{ name: 'Guida utente PRO', path: '/help/guide-pro' },
		{ name: 'FAQ', path: '/help' },
		{ name: 'Termini di servizio', path: '/legal/terms-of-service' },
		{ name: 'Cookie policy', path: '/legal/cookies' },
		{ name: 'Privacy policy', path: '/legal/privacy' },
		{ name: 'Contatti', path: '/contact' },
	],
	header: headerMenu(),
};

export default menu;
