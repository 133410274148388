import { useMultiStyleConfig, Text, Box, Divider } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
	AsyncCreatableSelect,
	AsyncSelect,
	CreatableSelect,
	Select as ReactSelect,
	ControlProps,
	chakraComponents,
	ChakraStylesConfig,
	GroupBase,
	OptionProps,
} from 'chakra-react-select';
import IBaseProps from '../../types/interfaces/IBaseProps';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useConfig from '../../hooks/useConfig';
import { TPackagingSize } from '../../types/types/TProduct';
import { PackagingSizeOption } from './Options';
interface IProps extends IBaseProps {
	variant?: string;
	instanceId?: string;
	addStyles?: ChakraStylesConfig<unknown, false, GroupBase<unknown>>;
	[rest: string]: any;
	type?: 'creatable' | 'async-creatable' | 'async' | 'standard';
	components?: any;
}

let customStyles = {
	indicatorSeparator: () => ({ display: 'none' }),
	container: (provided: any) => ({
		...provided,
		paddingInlineEnd: 0,
		paddingInlineStart: 0,
		w: '100%',
		marginTop: 0,
		marginBottom: 0,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	menuList: (provided: any) => ({
		...provided,
		marginTop: 0,
		marginBottom: 0,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	// valueContainer: (provided: any) => ({
	// 	...provided,
	// 	paddingInlineEnd: 0,
	// 	padding: '2px 12px',
	// 	cursor: 'pointer',
	// 	height: '100%',
	// 	minHeight: '100%',
	// 	display: 'flex',
	// 	w: '100%',
	// }),
	// control: (provided: any, state: ControlProps) => ({
	// 	...provided,
	// 	background: 'none',
	// 	border: 'unset',
	// 	outline: 'none',
	// 	boxShadow: 'none',
	// 	borderRadius: 'unset',
	// 	minHeight: 'unset',
	// 	maxHeight: '100%',
	// 	height: '100%',
	// }),
	placeholder: (provided: any) => ({
		...provided,
		color: 'rgba(0, 0, 0, 0.36)',
		fontWeight: 600,
		fontSize: '1rem',
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: 'inherit',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	}),
	menu: (provided: any) => ({
		...provided,
		zIndex: 999,
		marginTop: 0,
		marginBottom: 0,
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		bg: 'unset',
		w: '35px',
		h: '35px',
	}),
};

let customStylesCreatable = {
	...customStyles,
	dropdownIndicator: (provided: any) => ({ ...provided, display: 'none' }),
};

export default function Select(props: IProps) {
	const { variant, ...rest } = props;

	const styles = useMultiStyleConfig('Select', { variant });

	const commonProps = {
		__css: styles.field,
		className: props.className,
		instanceId: props.instanceId,
		...rest,
		px: 0,
		paddingInlineEnd: 0,
	};

	function getTableByType(
		type?: 'creatable' | 'async-creatable' | 'async' | 'standard',
	) {
		switch (type) {
			case 'creatable':
				return (
					<CreatableSelect
						chakraStyles={{
							...customStylesCreatable,
							...props.addStyles,
						}}
						placeholder={'Seleziona o crea un opzione'}
						{...commonProps}
						components={{ ...props.components, DropdownIndicator }}
					/>
				);
			case 'async-creatable':
				return (
					<AsyncCreatableSelect
						chakraStyles={{
							...customStylesCreatable,
							...props.addStyles,
						}}
						placeholder={'Seleziona o crea un opzione'}
						{...commonProps}
						components={{ ...props.components, DropdownIndicator }}
					/>
				);
			case 'async':
				return (
					<AsyncSelect
						chakraStyles={{
							...customStyles,
							...props.addStyles,
						}}
						placeholder={'Seleziona un opzione'}
						{...commonProps}
						components={{ ...props.components, DropdownIndicator }}
					/>
				);
			default:
				return (
					<ReactSelect
						chakraStyles={{
							...customStyles,
							...props.addStyles,
						}}
						placeholder={'Seleziona un opzione'}
						{...commonProps}
						components={{ ...props.components, DropdownIndicator }}
					/>
				);
		}
	}

	return <>{getTableByType(props.type)}</>;
}

const DropdownIndicator = (props: any) => {
	return (
		<chakraComponents.DropdownIndicator {...props}>
			{props.selectProps.menuIsOpen ? (
				<ChevronUpIcon
					color={
						props.selectProps.chakraStyles?.dropdownIndicator()
							.color || 'black'
					}
					boxSize={6}
				/>
			) : (
				<ChevronDownIcon
					color={
						props.selectProps.chakraStyles?.dropdownIndicator()
							.color || 'black'
					}
					boxSize={6}
				/>
			)}
		</chakraComponents.DropdownIndicator>
	);
};
