import { chakra, HStack, Box } from '@chakra-ui/react';
import useMenu from '../../hooks/useMenu';
import IBaseProps from '../../types/interfaces/IBaseProps';
import MegaMenu from '../../ui/MegaMenu';

function HeaderMenuDesktop(props: IBaseProps) {
	const menuType = 'header';
	const menu = useMenu(menuType);

	return (
		<HStack spacing={{base: 2, xl: 4}} className={props.className}>
			{menu.map(route => (
				<Box key={route.path} h={'100%'}>
					<MegaMenu route={route}></MegaMenu>
				</Box>
			))}
		</HStack>
	);
}

export default chakra(HeaderMenuDesktop);
