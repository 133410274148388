import { Box, useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import BottomMobileBar from '../../components/BottomMobileBar';
import Header from '../../components/Header';
import HeaderBottom from '../../components/HeaderBottom';
import HeaderTop from '../../components/HeaderTop';
import SectionFooter from '../../components/SectionFooter';
import useAuth from '../../hooks/useAuth';
import IBaseProps from '../../types/interfaces/IBaseProps';
import Section from '../../ui/Section';
import LayoutBase from '../LayoutBase';

export default function LayoutComplete(props: IBaseProps) {
	const { isAuth } = useAuth();
	const router = useRouter();
	const [isMobile] = useMediaQuery('(max-width: 768px)');

	useEffect(() => {
		const toastManager = document.getElementById(
			'chakra-toast-manager-bottom',
		);
		if (isAuth && toastManager && isMobile) {
			toastManager.style.bottom = '80px';
		}
	}, [isAuth]);

	const footerNoPaddingPath = ['/user'];
	const footerNoPadding = footerNoPaddingPath.some(path =>
		router.pathname.includes(path),
	);

	return (
		<LayoutBase>
			<Header sticky containerWidth={'88%'}>
				<HeaderTop />
				<HeaderBottom />
			</Header>
			<Box overflowX={'hidden'}>
				{props.children}
				<Section
					py={footerNoPadding ? 0 : { base: 8, md: 0 }}
					my={0}
					mb={{ base: 8, md: 4 }}
				>
					<SectionFooter />
				</Section>
				{isAuth && (
					<Box mt={'70px'} display={{ base: undefined, md: 'none' }}>
						<BottomMobileBar />
					</Box>
				)}
			</Box>
		</LayoutBase>
	);
}
