// theme/index.js
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

// Global style overrides
import styles from './styles';

// Foundational style overrides
import colors from './foundations/colors';
import fonts from './foundations/fonts';

// Component style overrides
import Button from './components/button';
import Select from './components/select';
import Input from './components/input';
import Link from './components/link';
import Heading from './components/heading';
import Spinner from './components/spinner';
import Modal from './components/modal';
import Checkbox from './components/checkbox';
import Icon from './components/icon';
import Accordion from './components/accordion';
import Textarea from './components/textarea';
import Toast from './components/toast';

const overrides = {
	styles,
	colors,
	...fonts,
	components: {
		Button,
		Input,
		Select,
		Link,
		Heading,
		Modal,
		Spinner,
		Checkbox,
		Icon,
		Accordion,
		Textarea,
		Toast,
	},
};

export default extendTheme(
	overrides,
	withDefaultColorScheme({ colorScheme: 'brandTwo' }),
	withDefaultColorScheme({
		colorScheme: 'brandGradient',
		components: ['Button'],
	}),
);
