import { Flex, Container, Box, chakra } from '@chakra-ui/react';
import React from 'react';
import HeaderMenuDesktop from '../HeaderMenuDesktop';
import SearchBar from '../SearchBar';
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils/types';
import * as CSS from 'csstype';
import IBaseProps from '../../types/interfaces/IBaseProps';

interface IProps extends IBaseProps {
	containerWidth?: Token<CSS.Property.MaxWidth | number, 'sizes'>;
}

function HeaderBottom(props: IProps) {
	return (
		<Flex
			w="100%"
			background={'white'}
			borderY="1px"
			borderColor={'gray.200'}
			justifyContent="center"
			minH={'45px'}
			className={props.className}
		>
			<Container maxW={props.containerWidth || '90%'} p={0}>
				<Flex h={'100%'}>
					<HeaderMenuDesktop
						display={{ base: 'none', lg: 'inherit' }}
					/>

					<SearchBar
						py={3}
						display={{ base: 'inherit', lg: 'none' }}
					/>
				</Flex>
			</Container>
		</Flex>
	);
}

export default chakra(HeaderBottom);
