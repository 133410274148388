let select = {
	baseStyle: {
		field: {
			border: 'none',
			width: 'auto',
		},
	},
	variants: {
		outline: {
			field: {
				border: '2px solid',
				borderColor: 'blackAlpha.300',
				bg: 'white',
			},
		},
		muted: {
			field: {
				bg: 'gray.100',
				color: 'gray.500',
				fontWeight: 'semibold',
			},
		},
	},
};

export default select;
