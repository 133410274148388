import { Box, chakra } from '@chakra-ui/react';
import React from 'react';
import IBaseProps from '../../types/interfaces/IBaseProps';

function Absolute(props: IBaseProps) {
	return (
		<Box
			pos={'absolute'}
			top="0"
			bottom="0"
			left="0"
			right="0"
			className={props.className}
		>
			{props.children}
		</Box>
	);
}

export default chakra(Absolute);
