import {
	Image,
	Spacer,
	Container,
	Flex,
	Link,
	useBreakpointValue,
	chakra,
} from '@chakra-ui/react';
import React from 'react';
import HeaderMenuMobile from '../HeaderMenuMobile';
import ButtonsHeader from '../ButtonsHeader';
import SearchBar from '../SearchBar';
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils/types';
import * as CSS from 'csstype';
import NextLink from 'next/link';
import IBaseProps from '../../types/interfaces/IBaseProps';

interface IProps extends IBaseProps {
	containerWidth?: Token<CSS.Property.MaxWidth | number, 'sizes'>;
	withCategory?: boolean;
}

function HeaderTop(props: IProps) {
	const logoImage = useBreakpointValue({
		base: '/images/logo.webp',
	});
	return (
		<Flex
			background={'white'}
			py={2}
			w="100%"
			justifyContent="center"
			className={props.className}
		>
			<Container maxW={props.containerWidth || '90%'} p={0}>
				<Flex align={'center'} w="100%" justifyContent="center">
					<NextLink href="/" passHref>
						<Link>
							<Image
								src={logoImage}
								minW={{ base: '180px', md: '130px' }}
								w={{ base: undefined, md: '150px' }}
								minH={'50px'}
								maxH={{ base: '50px', md: undefined }}
								objectFit={'contain'}
								alt={'Logo'}
							/>
						</Link>
					</NextLink>
					<Spacer />
					<SearchBar
						maxW={{ md: '300px', lg: '500px', xl: '800px' }}
						w="100%"
						display={{ base: 'none', lg: 'inherit' }}
						withCategory={props.withCategory ? true : false}
						mx={{ md: 8, lg: 10, xl: 12 }}
					/>
					<Spacer />
					<ButtonsHeader
						display={{ base: 'none', lg: 'inherit' }}
						buttonProps={{ px: 8 }}
					/>
					<Flex
						width={'100%'}
						display={{ base: 'inherit', lg: 'none' }}
						align={'center'}
					>
						<Spacer className="spacer" />
						<ButtonsHeader
							buttonProps={{
								fontSize: { base: 'md', sm: 'md' },
								px: { base: 4, sm: 6 },
								size: 'sm',
							}}
						/>
						<HeaderMenuMobile />
					</Flex>
				</Flex>
			</Container>
		</Flex>
	);
}

export default chakra(HeaderTop);
