import { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useRouter } from 'next/router';

const AppListener = () => {
	const router = useRouter();

	useEffect(() => {
		const listener = App.addListener(
			'appUrlOpen',
			(event: URLOpenListenerEvent) => {
				const slug = event.url.split('.app').pop();
				if (slug) {
					router.push(slug);
				}
			},
		);

		return () => {
			listener.remove();
		};
	}, []);

	return null;
};

export default AppListener;
