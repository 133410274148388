import { Search2Icon } from '@chakra-ui/icons';
import {
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	chakra,
} from '@chakra-ui/react';
import Select from '../../ui/Select';
import React, { useState } from 'react';
import IBaseProps from '../../types/interfaces/IBaseProps';
import { useRouter } from 'next/router';

interface IProps extends IBaseProps {
	withCategory?: boolean;
}

function SearchBar(props: IProps) {
	const router = useRouter();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const inputProps = props.withCategory
		? { variant: 'muted', borderStartRadius: 0 }
		: { variant: 'muted' };

	return (
		<Flex className={props.className} w="100%">
			{props.withCategory && (
				<Select
					instanceId={props.className}
					placeholder="Categoria"
					variant={'muted'}
					borderEnd={'1px'}
					borderEndColor={'gray.300'}
					paddingEnd={0}
					minW="110px"
					borderEndRadius={0}
					options={[
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
					]}
				></Select>
			)}
			<InputGroup size={'xl'}>
				<InputLeftElement pointerEvents={'none'}>
					<Search2Icon color={'gray.500'} boxSize={'1.2em'} mx={20} />
				</InputLeftElement>
				<Input
					{...inputProps}
					placeholder="Cerca articoli"
					size={'xl'}
					variant={'search'}
					onChange={evt => setSearchQuery(evt.target.value)}
					value={searchQuery}
					onKeyDown={evt => {
						if (searchQuery && evt.key === 'Enter') {
							router.push(`/search?q=${searchQuery}`);
							setSearchQuery('');
						}
					}}
				></Input>
			</InputGroup>
		</Flex>
	);
}

export default chakra(SearchBar);
