import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../utils/api';
import { deviceInfo as devInfo } from 'webtonative';

export default function useDeviceInfo() {
	const [isApp, setIsApp] = useState<boolean | undefined>(undefined);
	const [deviceInfo, setDeviceInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const device = async () => {
			try {
				const infoDev = await devInfo();
				setIsApp(true);
				setDeviceInfo(infoDev);
			} catch (err) {
				setIsApp(false);
			} finally {
				setIsLoading(false);
			}
		};

		setIsLoading(true);
		device();
	}, []);

	return {
		isLoading,
		isApp,
		deviceInfo,
	};
}
