import _difference from 'lodash/difference';
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../layout/LayoutBase/LayoutBase';
import socket from '../services/socket/index';
import API from '../utils/api';

interface IProps {
	channel?: string | string[];
}

export default function useSocket(props?: IProps) {
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const socketCtx = useContext(SocketContext)?.value;

	function socketAuth(channels: string[]) {
		socket.on(
			'authentication',
			async function (id: string, subscribeCb: Function) {
				socketCtx?.setIsConnecting(true);
				const userToken = await API.User.userToken();
				if (typeof subscribeCb === 'function') {
					subscribeCb({
						channels: channels,
						publishableKey:
							process.env.NEXT_PUBLIC_BACKEND_PUBLIC_API_KEYS,
						authToken: userToken,
					});

					socketCtx?.setConnectedChannels(channels);
				}
			},
		);
		socket.on('connect', () => {
			setIsConnected(true);
		});
		socket.on('disconnect', (reason: string) => {
			if (reason.includes('server')) {
				socket.connect();
			} else setIsConnected(false);
		});

		socket.on('willDisconnect', (reason: { error: string }) => {
			console.log(reason.error);
		});
	}

	useEffect(() => {
		if (socket.disconnected) {
			socket.connect();
			socketAuth(['']);
		}
		return () => {
			setIsConnected(false);
			socket.disconnect();
		};
	}, []);

	useEffect(() => {
		if (props?.channel && socketCtx?.setChannels) {
			const channels = Array.isArray(props.channel)
				? props.channel
				: [props.channel];
			if (channels.find(ch => ch.startsWith('conv'))) {
				socketCtx.setChannels(prev => [
					...new Set(
						[
							...prev.filter(ch => !ch.startsWith('conv')),
							...channels,
						].filter(el => el),
					),
				]);
			} else {
				socketCtx.setChannels(prev => [
					...new Set([...prev, ...channels].filter(el => el)),
				]);
			}
		}
	}, [props?.channel]);

	useEffect(() => {
		if (
			socketCtx &&
			_difference(socketCtx.channels, socketCtx.connectedChannels)
				.length > 0
		) {
			if (socketCtx.channels.length > 0) {
				socket.off('authentication');
				if (socket && socket.connected) {
					socket.disconnect();
				}
				socket.connect();

				socketAuth(socketCtx.channels);
			}
		}
	}, [socketCtx?.channels]);

	return { socket, connected: isConnected };
}
