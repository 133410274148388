import { ThemeComponentProps } from '@chakra-ui/react';
let link = {
	baseStyle: (props: ThemeComponentProps) => ({
		color: `${props.colorScheme}.500`,
		_focus: {
			boxShadow: 'none',
		},
		_hover: { textDecoration: 'unset' },
	}),
	variants: {
		footer: {
			color: 'gray.500',
			fontSize: 'sm',
			fontWeight: 'semibold',
		},
		linkTitle: {
			fontWeight: 'extrabold',
		},
	},
};

export default link;
