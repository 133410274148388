import API from '../utils/api';
import { useQuery } from 'react-query';
import useAuth from './useAuth';

export default function useBalance(enabled?: boolean) {
	const { user } = useAuth();
	const userPayerWallet =
		user?.platformData?._private?.mangoPay?.payer?.walletId;
	const inTransferBalance = user?.platformData?._private?.balance?.inTransfer;
	const userOwnerWallet =
		user?.platformData?._private?.mangoPay?.owner?.walletId;

	const pendingBalanceReq = useQuery(
		['wallet', userPayerWallet],
		async () => {
			return await API.User.getWallet(userPayerWallet!.toString());
		},
		{ enabled: !!userPayerWallet && (enabled === undefined || enabled) },
	);

	const availableBalanceReq = useQuery(
		['wallet', userOwnerWallet],
		async () => {
			return await API.User.getWallet(userOwnerWallet!.toString());
		},
		{ enabled: !!userOwnerWallet && (enabled === undefined || enabled) },
	);

	const availableBalance = availableBalanceReq.data?.Balance.Amount;
	const pendingBalance = pendingBalanceReq.data?.Balance.Amount;

	const totalBalance =
		parseInt(pendingBalance || '0') + parseInt(availableBalance || '0');

	return {
		pending: parseInt(pendingBalance || '0'),
		available: parseInt(availableBalance || '0'),
		inTransfer: inTransferBalance,
		total: totalBalance || 0,
		options: {
			refetch: () => {
				availableBalanceReq.refetch();
				pendingBalanceReq.refetch();
			},
		},
	};
}
