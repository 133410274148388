import { ChakraProvider } from '@chakra-ui/react';
import { TrackingHeadScript } from '@phntms/next-gtm';
import {
	FBPixelProvider,
	FBPixelScript,
} from '@rivercode/facebook-conversion-api-nextjs/components';
import '@ungap/has-own';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { NextPage } from 'next';
import NextProgress from 'next-progress';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { ReactElement, ReactNode, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ActiveCampaignProvider } from '../components/ActiveCampaign';
import ActiveCampaignScript from '../components/ActiveCampaign/ActiveCampaignScript';
import AppListener from '../components/AppListener';
import { MixpanelProvider } from '../components/MixPanel';
import LayoutComplete from '../layout/LayoutComplete';
import theme from '../theme';
import '../theme/css/datepicker.scss';
import '../theme/css/fileuploader.scss';
import '../theme/css/fonts.css';
import '../theme/css/scrollbar.scss';
import '../ui/DropdownSelect/styles.scss';
import Firebase from '../components/Firebase';

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 5,
		},
	},
});

export { queryClient };

function App({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout =
		Component.getLayout ??
		(page => (
			<LayoutComplete>
				<Component {...pageProps} />
			</LayoutComplete>
		));

	return (
		<>
			<AppListener />
			<Head>
				<title>BabyWanted</title>
				<meta
					name="description"
					content="Compra e Vendi gli articoli dei tuoi figli"
					key="description"
				/>
				<meta
					property="og:title"
					content="BabyWanted ™"
					key="og:title"
				/>
				<meta
					property="og:description"
					content="Compra e Vendi gli articoli dei tuoi figli"
					key="og:description"
				/>
				<meta
					property="og:image"
					content={`${process.env.NEXT_PUBLIC_FRONTEND}/images/logo_metadata.jpg`}
					key="image"
				/>
				<meta
					name="viewport"
					content="width=device-width, maximum-scale=1.0, initial-scale=1.0"
				/>
				<script
					async
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3675340829938372"
					crossOrigin="anonymous"
				></script>
				<meta
					name="google-adsense-account"
					content="ca-pub-3675340829938372"
				/>
			</Head>

			<Script id="clarity-tracking">
				{`
						(function(c,l,a,r,i,t,y){
							c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
							t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
							y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
						})(window, document, "clarity", "script", "hj64w990d0");
					`}
			</Script>
			<GoogleAnalytics trackPageViews />
			<FBPixelScript />
			<ActiveCampaignScript />
			<TrackingHeadScript
				id={process.env.NEXT_PUBLIC_GTM_ID}
				isGTM={true}
			/>

			<QueryClientProvider client={queryClient} contextSharing={true}>
				<ChakraProvider theme={theme}>
					<NextProgress
						delay={600}
						options={{
							easing: 'ease',
							minimum: 0.2,
							trickleRate: 0.1,
							trickleSpeed: 100,
						}}
					/>

					<MixpanelProvider
						token={'e62e79f0828d578ec5fed3d638bf50da'}
						config={{
							debug: false,
							track_pageview: true,
							persistence: 'localStorage',
						}}
					>
						<Firebase />
						<FBPixelProvider>
							<ActiveCampaignProvider>
								{getLayout(<Component {...pageProps} />)}
							</ActiveCampaignProvider>
						</FBPixelProvider>
					</MixpanelProvider>
					{/* <ReactQueryDevtools /> */}
				</ChakraProvider>
			</QueryClientProvider>
		</>
	);
}

export default App;
