import React, { useRef } from 'react';
import {
	IconButton,
	useDisclosure,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	Box,
	VStack,
	LinkBox,
	LinkOverlay,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Image,
	AccordionIcon,
	Heading,
	HStack,
	Divider,
	Flex,
	Spacer,
} from '@chakra-ui/react';
import {
	ExternalLinkIcon,
	HamburgerIcon,
	SmallCloseIcon,
} from '@chakra-ui/icons';
import useMenu from '../../hooks/useMenu';
import NextLink from 'next/link';
import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';

export default function HeaderMenuMobile() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const menuRef = useRef(null);
	const menuType = 'header';
	const menu = useMenu(menuType);
	const logoutService = useLogout();
	const { user, isAuth } = useAuth();

	async function doLogout() {
		await logoutService.logout();
		document.location.reload();
	}

	return (
		<>
			<IconButton
				aria-label="menu"
				ref={menuRef}
				icon={isOpen ? <SmallCloseIcon /> : <HamburgerIcon />}
				onClick={onOpen}
				variant={'ghost'}
				fontSize={'2xl'}
				justifyContent={'end'}
				minW={0}
			></IconButton>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				finalFocusRef={menuRef}
				placement={'right'}
				size={'md'}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader mt={3} mr={3} fontWeight={'extrabold'} py={1}>
						<LinkBox onClick={onClose} maxW={'130px'} border={0}>
							<NextLink href={'/'} passHref>
								<LinkOverlay border={0}>
									<Image
										border={0}
										src={'/images/logo.webp'}
										minW={{ base: '180px', md: '130px' }}
										w={{ base: undefined, md: '150px' }}
										minH={'50px'}
										maxH={{ base: '50px', md: undefined }}
										objectFit={'contain'}
										alt={'Logo'}
									/>
								</LinkOverlay>
							</NextLink>
						</LinkBox>
						<DrawerCloseButton
							mt={4}
							mr={3}
							fontSize={'xl'}
							color={'blackAlpha.700'}
						/>
					</DrawerHeader>
					<DrawerBody
						mb={6}
						mt={3}
						pb={6}
						css={{
							'&::-webkit-scrollbar': {
								width: '12px',
								height: '12px',
							},
							'&::-webkit-scrollbar-button': {
								width: '0px',
								height: '0px',
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#e6e4de',
								border: '4px solid #ffffff',
								borderRadius: '5000px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								background: '#e6e4de',
							},
							'&::-webkit-scrollbar-thumb:active': {
								background: '#e6e4de',
							},
							'&::-webkit-scrollbar-track': {
								background: 'transparent',
							},
							'&::-webkit-scrollbar-track:hover': {
								background: 'transparent',
							},
							'&::-webkit-scrollbar-track:active': {
								background: 'transparent',
							},
							'&::-webkit-scrollbar-corner': {
								background: 'transparent',
							},
						}}
					>
						<Heading
							fontSize={'sm'}
							fontWeight={700}
							color={'blackAlpha.500'}
							letterSpacing={0.5}
							mb={3}
							as={'h1'}
						>
							Categorie
						</Heading>
						<VStack spacing={2} align={'start'}>
							{menu.map(route => (
								<Accordion
									key={route.path}
									allowToggle
									variant={'menuMobile'}
									w={'100%'}
								>
									<AccordionItem>
										<Heading w={'100%'} as={'span'}>
											<AccordionButton
												fontSize={'lg'}
												fontWeight={'normal'}
												px={2}
											>
												<Box
													flex={1}
													textAlign={'left'}
												>
													{route.name}
												</Box>
												<AccordionIcon />
											</AccordionButton>
										</Heading>
										<AccordionPanel>
											<VStack
												spacing={2}
												align={'start'}
												pl={3}
												my={3}
											>
												<LinkBox
													w={'100%'}
													py={2}
													onClick={onClose}
												>
													<NextLink
														href={route.path}
														passHref
													>
														<LinkOverlay
															fontWeight={
																'semibold'
															}
														>
															Tutto
														</LinkOverlay>
													</NextLink>
												</LinkBox>

												{route.children && (
													<>
														<Divider />
														{route.children.map(
															subMenu => (
																<LinkBox
																	key={
																		subMenu.path
																	}
																	w={'100%'}
																	py={2}
																	onClick={
																		onClose
																	}
																>
																	<NextLink
																		href={
																			subMenu.path
																		}
																		passHref
																	>
																		<LinkOverlay>
																			{
																				subMenu.name
																			}
																		</LinkOverlay>
																	</NextLink>
																</LinkBox>
															),
														)}
													</>
												)}
											</VStack>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							))}
						</VStack>
						<Heading
							fontSize={'sm'}
							fontWeight={700}
							color={'blackAlpha.500'}
							letterSpacing={0.5}
							mt={8}
							mb={3}
							as={'h1'}
						>
							Assistenza
						</Heading>
						<VStack spacing={2} align={'start'}>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/help/guide'} passHref>
									<LinkOverlay>Guida BabyWanted</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/help/guide-pro'} passHref>
									<LinkOverlay>
										Guida BabyWanted PRO
									</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/lp/security'} passHref>
									<LinkOverlay>
										Sicurezza e affidabilità
									</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/help'} passHref>
									<LinkOverlay>FAQ</LinkOverlay>
								</NextLink>
							</LinkBox>
						</VStack>
						<Heading
							fontSize={'sm'}
							fontWeight={700}
							color={'blackAlpha.500'}
							letterSpacing={0.5}
							mt={8}
							mb={3}
							as={'h1'}
						>
							Azienda
						</Heading>
						<VStack spacing={2} align={'start'}>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/about-us'} passHref>
									<LinkOverlay>Chi siamo</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/contact'} passHref>
									<LinkOverlay>Contatti</LinkOverlay>
								</NextLink>
							</LinkBox>
						</VStack>
						<Heading
							fontSize={'sm'}
							fontWeight={700}
							color={'blackAlpha.500'}
							letterSpacing={0.5}
							mt={8}
							mb={3}
							as={'h1'}
						>
							Politiche
						</Heading>
						<VStack spacing={2} align={'start'}>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink
									href={'/legal/terms-of-service'}
									passHref
								>
									<LinkOverlay>
										Termini di servizio
									</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
							>
								<NextLink href={'/legal/cookies'} passHref>
									<LinkOverlay>
										Informativa sui cookies
									</LinkOverlay>
								</NextLink>
							</LinkBox>
							<LinkBox
								p={2}
								w={'100%'}
								fontSize={'lg'}
								fontWeight={'normal'}
								onClick={onClose}
								pb={4}
							>
								<NextLink href={'/legal/privacy'} passHref>
									<LinkOverlay>
										Informativa sulla privacy
									</LinkOverlay>
								</NextLink>
							</LinkBox>
							<Divider></Divider>
							{isAuth && (
								<Box
									p={2}
									w={'100%'}
									fontSize={'lg'}
									onClick={() => {
										doLogout();
										onClose();
									}}
									alignItems={'center'}
									fontWeight={800}
									pt={4}
									cursor={'pointer'}
								>
									LOGOUT
									<ExternalLinkIcon ml={3} mt={'-5px'} />
								</Box>
							)}
						</VStack>
						<Flex w={'100%'} mt={8} mb={4} justify={'center'}>
							<Image
								src={'/images/logo_footer_24.png'}
								w={'230px'}
								alt={'logo_footer'}
							/>
						</Flex>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			{/* <Menu onClose={onClose} onOpen={onOpen}>
				<MenuButton
					as={IconButton}
					icon={isMenuOpen ? <SmallCloseIcon /> : <HamburgerIcon />}
					variant="ghost"
					fontSize={'2xl'}
				></MenuButton>
				<MenuList w={'100%'} minW="0">
					<MenuItem icon={<AddIcon />} command="⌘T">
						New Tab
					</MenuItem>
					<MenuItem icon={<ExternalLinkIcon />} command="⌘N">
						New Window
					</MenuItem>
					<MenuItem icon={<RepeatIcon />} command="⌘⇧N">
						Open Closed Tab
					</MenuItem>
					<MenuItem icon={<EditIcon />} command="⌘O">
						Open File...
					</MenuItem>
				</MenuList>
			</Menu> */}
		</>
	);
}
