let styles = {
	global: {
		html: {
			fontSize: '16px',
			height: '100%',
			'*': {
				_focus: {
					boxShadow: 'none!important',
				},
			},
		},
		body: {
			minH: '100%',
		},
		'.uppy-Dashboard-AddFiles-info': {
			display: 'block',
		},
		'.filepond--item':{
			width: 'calc(50% - 0.5em)',
		},
		'@media (max-width: 768px)': {
			'.filepond--item': {
				width: 'calc(100% - 0.5em)',
			}
		},
	},
};

export default styles;
