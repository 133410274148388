import {
	FirebaseMessaging,
	GetTokenOptions,
	NotificationActionPerformedEvent,
	NotificationReceivedEvent,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { getApps, initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';
import { IUser } from '../services/api/user/types/IResponse';
import API from '../utils/api';
import useAuth from './useAuth';
import useDeviceInfo from './useDeviceInfo';
import * as AppFirebaseMessaging from 'webtonative/Firebase/Messaging';

export default function usePushNotification() {
	const [token, setToken] = useState('');
	const { user, isAuth } = useAuth();
	const { isApp, isLoading, deviceInfo } = useDeviceInfo();

	useEffect(() => {
		let connectedFirebase = false;

		const initPushNotifications = async () => {
			if (isAuth) {
				await initializeFirebase();

				FirebaseMessaging.addListener(
					'notificationReceived',
					(event: NotificationReceivedEvent) => {
						console.log('notificationReceived: ', { event });
					},
				);
				FirebaseMessaging.addListener(
					'notificationActionPerformed',
					(event: NotificationActionPerformedEvent) => {
						console.log('notificationActionPerformed: ', {
							event,
						});
					},
				);

				if (!isApp) {
					const handleServiceWorkerMessage = (
						event: MessageEvent<any>,
					) => {
						console.log('serviceWorker message: ', { event });
						const notification = new Notification(
							event.data.notification.title,
							{
								body: event.data.notification.body,
							},
						);

						notification.onclick = event => {
							console.log('notification clicked: ', { event });
						};
					};

					navigator.serviceWorker.addEventListener(
						'message',
						handleServiceWorkerMessage,
					);

					await requestPermissions();
				} else {
				}

				try {
					await getTokenAndSave();
				} catch (error) {
					console.error(
						'Failed to request notification permission:',
						error,
					);
				} finally {
					connectedFirebase = true;
				}
			}
		};

		if (isLoading === false) {
			initPushNotifications();
		}

		return () => {
			if (connectedFirebase) {
				FirebaseMessaging.removeAllListeners();
			}
		};
	}, [user, isLoading]);

	const requestPermissions = async () => {
		await FirebaseMessaging.requestPermissions();
	};

	const getTokenAndSave = async () => {
		let options: GetTokenOptions | undefined;
		if (!isApp) {
			options = {
				vapidKey: process.env.FIREBASE_VAPID_KEY,
				serviceWorkerRegistration:
					await navigator.serviceWorker.register(
						'/firebase-messaging-sw.js',
					),
			};

			const { token } = await FirebaseMessaging.getToken();
			setToken(token);
			saveTokenToDB(token);
		} else {
			AppFirebaseMessaging.getFCMToken({
				callback: function (data: { token: string }) {
					setToken(data.token);
					saveTokenToDB(data.token);
				},
			});
		}
	};

	const initializeFirebase = () => {
		if (getApps().length === 0) {
			initializeApp({
				apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
				authDomain: 'baby-wanted.firebaseapp.com',
				projectId: 'baby-wanted',
				storageBucket: 'baby-wanted.appspot.com',
				messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
				appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
				measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
			});
		}
	};

	const saveTokenToDB = async (token: string) => {
		if (isAuth && user?.id) {
			const platformKey = isApp ? 'mobile' : 'web';

			if (
				user.metadata?._private?.firebaseToken?.[platformKey] !== token
			) {
				await API.User.update(user.id, {
					metadata: {
						_private: {
							firebaseToken: {
								...user.metadata?._private?.firebaseToken,
								[platformKey]: token,
							},
						},
					},
				});
			}
		}
	};

	return {
		token,
	};
}
