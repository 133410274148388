import { createInstance } from 'stelace';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';

const stelace = createInstance({
	apiKey: process.env.NEXT_PUBLIC_BACKEND_PUBLIC_API_KEYS,
});

stelace.setHost(
	process.env.NEXT_PUBLIC_BACKEND_HOST,
	process.env.NEXT_PUBLIC_BACKEND_PORT,
	process.env.NEXT_PUBLIC_BACKEND_PROTOCOL,
);

const cookieName = 'bw-tkn';

stelace.setTokenStore({
	getTokens() {
		const key = cookieName;

		const rawValue = getCookie(key);
		return typeof window !== 'undefined' && typeof rawValue === 'string'
			? JSON.parse(rawValue)
			: {};
	},

	setTokens(tokens: any) {
		if (!tokens || typeof tokens !== 'object') {
			throw new Error('Expected object as tokens value');
		}

		const key = cookieName;
		setCookie(key, JSON.stringify(tokens), {
			maxAge: 60 * 60 * 24 * 400,
			secure: true,
		});
	},

	removeTokens() {
		const key = cookieName;
		deleteCookie(key);
	},
});

export default stelace;
