let colors: any = {
	brandOne: {
		50: '#f8e8ed',
		100: '#f2d2da',
		200: '#ebbbc8',
		300: '#e4a4b6',
		400: '#de8da3',
		500: '#e86d8a',
		600: '#b36279',
		700: '#8f4f61',
		800: '#6b3b48',
		900: '#472730',
	},
	brandTwo: {
		50: '#daffff',
		100: '#aff9fd',
		200: '#82f3fa',
		300: '#55eff7',
		400: '#2eeaf3',
		500: '#3cc6d8',
		600: '#08a2aa',
		700: '#00747a',
		800: '#00464a',
		900: '#00191b',
	},
};

let brandGradient: any = {};
for (let [key, color] of Object.entries(colors.brandOne)) {
	brandGradient[
		key
	] = `linear-gradient(45deg, ${color} 38%, ${colors.brandTwo[key]} 62%)`;
}

// let brandGradient = {
// 	500: `linear-gradient(45deg, ${colors.brandOne[500]} 38%, ${colors.brandTwo[500]} 62%)`,
// };

colors.brandGradient = brandGradient;

export default colors;
