import { APIProduct } from './product';
import { APIUser } from './user';
import { APIShop } from './shop';
import { APIChat } from './chat';
import { APITransactions } from './transactions';
import Axios from './axios';

export default class API {
	private _http: Axios;
	private _Product: APIProduct;
	private _User: APIUser;
	private _Shop: APIShop;
	private _Chat: APIChat;
	private _Transactions: APITransactions;

	constructor(baseUrl: string) {
		this._http = new Axios(baseUrl);
		this._Product = new APIProduct(this._http);
		this._User = new APIUser(this._http);
		this._Shop = new APIShop(this._http);
		this._Chat = new APIChat(this._http);
		this._Transactions = new APITransactions(this._http);
	}

	get Product() {
		return this._Product;
	}

	get User() {
		return this._User;
	}

	get Shop() {
		return this._Shop;
	}

	get Chat() {
		return this._Chat;
	}

	get Transactions() {
		return this._Transactions;
	}
}
