import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Button,
	ButtonProps,
	chakra,
	Flex,
	HStack,
	Icon,
	// Icon,
	Image,
	Link,
	LinkBox,
	LinkOverlay,
	Menu,
	MenuButton,
	MenuItem,
	Text,
	MenuList,
	Spacer,
	useBreakpointValue,
	Box,
	Switch,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { FaBox } from 'react-icons/fa';
import { IoChatbubbles } from 'react-icons/io5';
import { useInfiniteQuery, useQuery } from 'react-query';
import useAuth from '../../hooks/useAuth';
import useBalance from '../../hooks/useBalance';
import useSocket from '../../hooks/useSocket';
import useSocketEvent from '../../hooks/useSocketEvent';
import { IMessage } from '../../services/api/chat/types/IResponse';
import { Price } from '../../services/api/product';
import IBaseProps from '../../types/interfaces/IBaseProps';
import Absolute from '../../ui/Absolute';
import API from '../../utils/api';
import ButtonLogout from '../ButtonLogout';

interface IProps extends IBaseProps {
	buttonProps?: ButtonProps;
}

function HeaderButtons(props: IProps) {
	// const { isOpen, onOpen, onClose } = useDisclosure();
	const buttonSizes = props.buttonProps?.size || 'md';
	const fontSizes = props.buttonProps?.fontSize || '2xl';
	const MainButtonText = useBreakpointValue({
		base: 'Accedi',
		md: 'Registrati',
	});
	const MainButtonLink = useBreakpointValue({
		base: '/auth/login',
		md: '/auth/signup',
	});
	const { isAuth, user } = useAuth();
	const socket = useSocket();
	const chatNotify = useSocketEvent<IMessage>({
		socket: socket.socket,
		eventName: 'chatNotify',
	});
	const chatRead = useSocketEvent<IMessage>({
		socket: socket.socket,
		eventName: 'chatRead',
	});
	const [countNewMessages, setCountNewMessages] = useState<number>(0);
	const [requestBalance, setRequestBalance] = useState<boolean>(false);
	const balance = useBalance(requestBalance);

	// useEffect(() => {
	// 	console.log(
	// 		'REFETCH MEX NOT READ',
	// 		!!chatNotify.eventData,
	// 		!!chatRead.eventData,
	// 	);
	// 	messagesNotRead.remove();
	// 	messagesNotRead.refetch();
	// }, [chatRead.eventData]);

	const messagesNotRead = useQuery(
		[
			'messages',
			'not-read',
			user?.id,
			chatRead.eventData?.id,
			chatNotify.eventData?.id,
		],
		async () =>
			await API.Chat.list(
				{ cursor: 1, limit: 1 },
				{ receiverId: user!.id, read: false },
			),
		{ enabled: !!user },
	);

	useEffect(() => {
		if (messagesNotRead.isSuccess) {
			setCountNewMessages(
				messagesNotRead.data
					? (messagesNotRead.data.items.paginationMeta?.nbResults ||
							0) -
							messagesNotRead.data.items.filter(
								mex => mex.metadata.openChat,
							).length
					: 0,
			);
		}
	}, [messagesNotRead]);

	const lastOrders = useQuery(
		['orders', 'list'],
		async () =>
			await API.Transactions.list(
				{ cursor: 1, limit: 100 },
				{
					ownerId: user?.id,
					createdDate: {
						gte: new Date(
							new Date().getTime() - 5 * 24 * 60 * 60 * 1000,
						).toISOString(),
					},
				},
			),
		{ enabled: !!user },
	);
	const lastOrdersToManage = lastOrders.data?.items.filter(
		order => order.status === 'validated',
	);

	return (
		<HStack
			h={'100%'}
			w={{ base: '100%', md: 'auto' }}
			justify={{ base: 'flex-end', md: 'center' }}
			className={props.className}
			mr={{ base: 6, lg: 0 }}
		>
			{!isAuth ? (
				<>
					{/* <Button
						onClick={onOpen}
						{...props.buttonProps}
						size={buttonSizes}
						fontSize={fontSizes}
						fontWeight={800}
						mr={{ base: 5, md: 'inherit' }}
					>
						{MainButtonText}
					</Button>
					<ModalLogin isOpen={isOpen} onClose={onClose}></ModalLogin> */}
					<LinkBox mr={{ base: 2, md: 0 }}>
						{MainButtonLink && (
							<Button
								{...props.buttonProps}
								size={buttonSizes}
								fontSize={fontSizes}
								fontWeight={800}
							>
								<NextLink href={MainButtonLink} passHref>
									<LinkOverlay>{MainButtonText}</LinkOverlay>
								</NextLink>
							</Button>
						)}
					</LinkBox>
					<LinkBox display={{ base: 'none', md: 'block' }}>
						<Button
							{...props.buttonProps}
							size={buttonSizes}
							fontSize={fontSizes}
							fontWeight={800}
							variant={'noBorder'}
						>
							<NextLink href={'/auth/login'} passHref>
								<LinkOverlay>Login</LinkOverlay>
							</NextLink>
						</Button>
					</LinkBox>
				</>
			) : (
				<>
					<HStack
						spacing={{ base: 2, md: 4, lg: 6 }}
						h={'100%'}
						d={{ base: 'none', md: 'inherit' }}
					>
						<NextLink href={'/account/orders'} passHref>
							<Link pos={'relative'} h={'100%'}>
								<Icon
									verticalAlign={'middle'}
									as={FaBox}
									boxSize={6}
									color={'gray.400'}
								></Icon>
								{lastOrdersToManage &&
									lastOrdersToManage.length > 0 && (
										<Absolute
											right={0}
											left={'60%'}
											h={'100%'}
										>
											<Flex
												borderRadius={'full'}
												bgColor={'brandOne.500'}
												h={'15px'}
												w={'fit-content'}
												minW={'15px'}
												maxW={'25px'}
												maxH={'15px'}
												justify={'center'}
												align={'center'}
												fontSize={'xs'}
												fontWeight={700}
												color={'white'}
												lineHeight={0}
												px={1}
											>
												{/* lastOrdersToManage?.length */}
											</Flex>
										</Absolute>
									)}
							</Link>
						</NextLink>
						<NextLink href={'/wishlist'} passHref>
							<Link pos={'relative'} h={'100%'}>
								<Icon
									verticalAlign={'middle'}
									as={AiFillHeart}
									boxSize={8}
									color={'brandOne.500'}
								></Icon>
							</Link>
						</NextLink>
						<NextLink
							href={
								typeof window !== 'undefined' &&
								window.location.href.includes('chat')
									? '#'
									: '/chat'
							}
							passHref
						>
							<Link pos={'relative'} h={'100%'}>
								<Icon
									verticalAlign={'middle'}
									as={IoChatbubbles}
									boxSize={8}
									color={'brandTwo.500'}
								></Icon>
								{countNewMessages > 0 && (
									<Absolute right={0} left={'60%'} h={'100%'}>
										<Flex
											borderRadius={'full'}
											bgColor={'brandOne.500'}
											h={'15px'}
											w={'fit-content'}
											minW={'15px'}
											maxW={'25px'}
											maxH={'15px'}
											justify={'center'}
											align={'center'}
											fontSize={'xs'}
											fontWeight={700}
											color={'white'}
											lineHeight={0}
											px={1}
										>
											{/* {countNewMessages} */}
										</Flex>
									</Absolute>
								)}
							</Link>
						</NextLink>
						<NextLink href={'/product/new'} passHref>
							<Link mr={{ base: 5, lg: 'inherit' }}>
								<Button>Vendi</Button>
							</Link>
						</NextLink>
						<Menu isLazy>
							<MenuButton onClick={() => setRequestBalance(true)}>
								<Flex align={'center'} w={'60px'}>
									<Image
										src={user?.metadata?.imgProfile}
										alt={'profile-image'}
										fallbackSrc={`https://ui-avatars.com/api/?name=${
											user?.firstname +
											'-' +
											user?.lastname
										}`}
										w={'40px'}
										h={'40px'}
										objectFit={'cover'}
										borderRadius={'full'}
									></Image>
									<ChevronDownIcon
										w={'10px'}
										boxSize={6}
									></ChevronDownIcon>
								</Flex>
							</MenuButton>
							<MenuList>
								{user?.roles?.includes('dev') && (
									<>
										<MenuItem fontWeight={'black'}>
											<LinkBox
												h={'100%'}
												w={'100%'}
												display="flex"
												py={1}
												alignItems={'center'}
											>
												<NextLink
													href={`/bw-admin`}
													passHref
												>
													<LinkOverlay>
														ADMIN
													</LinkOverlay>
												</NextLink>
											</LinkBox>
										</MenuItem>
									</>
								)}

								<MenuItem fontWeight={'bold'}>
									<LinkBox
										h={'100%'}
										w={'100%'}
										display="flex"
										py={1}
										alignItems={'center'}
									>
										<NextLink
											href={`/user/${user?.id}`}
											passHref
										>
											<LinkOverlay>
												Il tuo profilo
											</LinkOverlay>
										</NextLink>
									</LinkBox>
								</MenuItem>

								<MenuItem fontWeight={'bold'}>
									<LinkBox
										h={'100%'}
										w={'100%'}
										display="flex"
										py={1}
										alignItems={'center'}
									>
										<NextLink
											href={'/account/settings/data'}
											passHref
										>
											<LinkOverlay>
												Impostazioni
											</LinkOverlay>
										</NextLink>
									</LinkBox>
								</MenuItem>

								<MenuItem fontWeight={'bold'}>
									<LinkBox
										h={'100%'}
										w={'100%'}
										display="flex"
										py={1}
										alignItems={'center'}
									>
										<NextLink
											href={'/account/orders'}
											passHref
										>
											<LinkOverlay>
												I tuoi ordini
											</LinkOverlay>
										</NextLink>
									</LinkBox>
								</MenuItem>

								<MenuItem fontWeight={'bold'}>
									<Flex
										align={'center'}
										justify={'space-between'}
										w={'100%'}
									>
										<LinkBox
											h={'100%'}
											w={'100%'}
											display="flex"
											py={1}
											alignItems={'center'}
										>
											<NextLink
												href={'/account/balance'}
												passHref
											>
												<LinkOverlay>
													Il tuo saldo
												</LinkOverlay>
											</NextLink>
										</LinkBox>
										<Spacer></Spacer>
										<Box h={'100%'}>
											<Text
												fontWeight={500}
												color={'blackAlpha.700'}
												lineHeight={1}
												fontSize={'md'}
											>
												{Price.format(balance.total)}
											</Text>
										</Box>
									</Flex>
								</MenuItem>

								<MenuItem
									textAlign={'left'}
									py={0}
									h={'100%'}
									as={'div'}
								>
									<ButtonLogout
										w={'100%'}
										variant={'ghost'}
										textAlign={'left'}
										p={0}
										justifyContent={'start'}
										fontWeight={'bold'}
									></ButtonLogout>
								</MenuItem>
							</MenuList>
						</Menu>
					</HStack>
				</>
			)}
		</HStack>
	);
}

export default chakra(HeaderButtons);
