let textarea = {
	variants: {
		outline: {
			border: `2px solid`,
			borderColor: `blackAlpha.300`,
			bg: 'white',
		},
		flushed: {
			borderBottom: `2px solid`,
			borderColor: `blackAlpha.300`,
		},
	},
	sizes: {
		xl: {
			field: { fontSize: 'lg', px: 5, h: 12, borderRadius: 'lg' },
			addon: { fontSize: 'lg', px: 5, h: 12, borderRadius: 'lg' },
		},
	},
};

export default textarea;
