let accordion = {
	variants: {
		menuMobile: {
			button: {
				_focus: {
					boxShadow: 'none',
				},
				_hover: {
					bg: 'none',
				},
			},
			container: {
				border: 'unset',
			},
		},
	},
};

export default accordion;
