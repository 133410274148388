import io from 'socket.io-client';

let socket = io(`${process.env.NEXT_PUBLIC_BACKEND}/signal`, {
	path: '/signal',
	autoConnect: false,
	transports: ['websocket'],
});
// // // //console.log('socket before: ', socket);

export default socket;
