import Axios from '../axios';
import stelace from '../stelace';
import IAPIProduct from './types/IAPIProduct';
import * as Response from './types/IResponse';
import * as Options from './types/IOptions';
import { IPage, IPageOptions } from '../../../types/interfaces/IPage';
import IMerchantProduct from "../../../types/interfaces/IMerchantProduct";
import API from "../../../utils/api";

export default class APIProduct implements IAPIProduct {
	private http: Axios;

	constructor(http: Axios) {
		this.http = http;
	}

	public async create(
		product: Partial<Options.IProduct>,
	): Promise<Response.IProduct> {
		let createProduct = await stelace.assets.create(product);

		return createProduct;
	}

	public async get(id: string): Promise<Response.IProduct> {
		let product = await stelace.assets.read(id);

		return {
			...product,
			id: product.id,
			createdDate: product.createdDate,
			updatedDate: product.updatedDate,
		};
	}

	public async list(
		pageOptions: IPageOptions,
		options?: Options.IProduct,
	): Promise<IPage<Response.IProduct> | undefined> {
		let product = await stelace.assets.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 100,
			...options,
		});

		return product.length > 0
			? {
					items: product,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async update(
		productId: string,
		product: Partial<Options.IProduct>,
	): Promise<Response.IProduct> {
		let updatedProduct = await stelace.assets.update(productId, product);
		return updatedProduct;
	}

	public async delete(
		productId: string,
	): Promise<{id: string}> {
		let updatedProduct = await stelace.assets.remove(productId);
		return updatedProduct;
	}

	public async sendToMerchant(
		data: IMerchantProduct,
	): Promise<void> {
		await stelace.forward.post(
			process.env.NEXT_PUBLIC_FRONTEND + '/api/uploadToGoogleMerchant',
			data
		);
	}

	public async updateInMerchant(
		data: IMerchantProduct,
	): Promise<void> {
		await stelace.forward.post(
			process.env.NEXT_PUBLIC_FRONTEND + '/api/updateInGoogleMerchant',
			data
		);
	}

	public async updateProductViewCount(
		productId: string
	): Promise<void> {
		await	API.Shop.createEvent('asset_updated_view_count',{objectId:productId});
	}
}
