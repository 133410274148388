import {
	chakra,
	ChakraProps,
	Container,
	Flex,
	FlexProps,
	Heading,
	Link,
	Spacer,
	SystemProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';
import IBaseProps from '../../types/interfaces/IBaseProps';

interface IProps extends IBaseProps {
	title?: string;
	link?: { path: string; title: string };
	image?: React.ReactNode;
	imageOrientation?: 'right' | 'left';
	titleColor?: string;
	borderless?: boolean;
	fullLength?: boolean;
	maxW?: SystemProps['maxW'];
	titleProps?: FlexProps;
	borderlessPadding?: ChakraProps['padding'];
}

function Section(props: IProps) {
	return (
		<Container
			maxW={props.fullLength ? '' : props.maxW || 'container.xl'}
			my={8}
			px={props.borderless || props.fullLength ? 0 : undefined}
			className={props.className}
		>
			<Flex
				wrap={'wrap'}
				w={'100%'}
				justify={{ base: 'center', md: 'space-between' }}
				mt={props.image ? 20 : undefined}
			>
				<Flex
					px={
						props.borderless && props.borderlessPadding
							? props.borderlessPadding
							: undefined
					}
					flexFlow={'column'}
					w={
						props.image
							? {
									base: '100%',
									md: '55%',
									lg: '65%',
									xl: '70%',
							  }
							: '100%'
					}
					align={
						props.image
							? props.imageOrientation === 'left'
								? { base: 'start', md: 'end' }
								: 'start'
							: 'center'
					}
					// textAlign={
					// 	props.imageOrientation === 'left'
					// 		? { md: 'right' }
					// 		: undefined
					// }
					justify={'center'}
				>
					{props.title && (
						<Flex
							alignItems={'center'}
							justify={'start'}
							my={8}
							w={'100%'}
							{...props.titleProps}
						>
							<Heading
								variant={'sectionTitle'}
								color={props.titleColor}
								fontSize={{ base: '3xl', md: undefined }}
							>
								{props.title}
							</Heading>

							{props.link?.path && (
								<>
									<Spacer />
									<NextLink href={props.link?.path} passHref>
										<Link variant={'linkTitle'}>
											{props.link?.title}
										</Link>
									</NextLink>
								</>
							)}
						</Flex>
					)}

					{props.children}
				</Flex>
				{props.image ? (
					<Flex
						justifyContent={'center'}
						order={
							props.imageOrientation === 'right'
								? { base: -1, md: 'unset' }
								: { base: -1 }
						}
						w={{ base: '100%', sm: 'auto' }}
						maxW={{ base: '100%', lg: '35%', xl: '26%' }}
					>
						{props.image}
					</Flex>
				) : null}
			</Flex>
		</Container>
	);
}

export default chakra(Section);
