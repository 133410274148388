export class Price {
	private price: number;
	private currency: string;
	private locale: string;

	constructor(price: number, currency?: string, locale?: string) {
		this.price = price;
		this.currency = currency || 'EUR';
		this.locale = locale || 'it-IT';
	}

	public static format(
		price: number,
		currency: string = 'EUR',
		locale: string = 'it-IT',
	): string {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currency.toUpperCase(),
		}).format(price / 100);
	}

	public toNumber(precision: number = 2): number {
		return parseFloat(this.price.toFixed(precision));
	}

	public toString(): string {
		return Price.format(this.price, this.currency, this.locale);
	}
}
