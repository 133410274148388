import { ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	LinkBox,
	LinkOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spacer,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import IBaseProps from '../../types/interfaces/IBaseProps';
import IMenuRoutes from '../../types/interfaces/IMenuRoutes';

interface IProps extends IBaseProps {
	route: IMenuRoutes;
}

function MegaMenuChild({ route }: IProps) {
	const router = useRouter();

	return (
		<Box w={'100%'}>
			<Popover
				trigger={'hover'}
				placement={'right-start'}
				offset={[-1, 0]}
				isLazy
			>
				<PopoverTrigger>
					<Flex align={'center'} px={4} py={2}>
						<Box
							w={'100%'}
							h={'100%'}
							display="flex"
							alignItems={'center'}
							onClick={() => router.push(route.path)}
							fontWeight={'bold'}
							color={'blackAlpha.700'}
						>
							{route.name}
						</Box>
						<Spacer />
						<ChevronRightIcon boxSize={5} />
					</Flex>
				</PopoverTrigger>
				{route.children && (
					<PopoverContent borderTopRadius={0} py={0}>
						<PopoverBody p={0}>
							{route.children.map(subcat => (
								<LinkBox
									key={subcat.path}
									h={'100%'}
									display="flex"
									px={4}
									py={2}
									alignItems={'center'}
									_hover={{ bg: 'gray.50' }}
								>
									<NextLink href={subcat.path} passHref>
										<LinkOverlay>
											<Box
												fontWeight={'bold'}
												color={'blackAlpha.700'}
											>
												{subcat.name}
											</Box>
										</LinkOverlay>
									</NextLink>
								</LinkBox>
							))}
						</PopoverBody>
					</PopoverContent>
				)}
			</Popover>
		</Box>
	);
}

export default MegaMenuChild;
