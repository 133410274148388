import React from 'react';
import { Flex } from '@chakra-ui/react';
import IBaseProps from '../../types/interfaces/IBaseProps';
import ISearchOptions from '../../types/interfaces/ISearchOptions';

interface IProps extends IBaseProps {
	sticky?: boolean;
	children?: React.ReactNode;
	containerWidth?: string | number;
	searchOptions?: ISearchOptions;
}

export default function Header(props: IProps) {
	// Create children with props to pass container width to every header element
	const childrenWithProps = React.Children.map(props.children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				// @ts-ignore
				containerWidth: props.containerWidth,
				searchOptions: props.searchOptions,
			});
		}
		return child;
	});

	return (
		<Flex
			pos={props.sticky ? 'sticky' : 'inherit'}
			top={0}
			flexFlow="column"
			justifyContent="center"
			alignItems="center"
			zIndex={'sticky'}
		>
			{childrenWithProps}
		</Flex>
	);
}
