import { createContext, useContext } from 'react';
import mixpanel, { Config, Mixpanel } from 'mixpanel-browser';

interface IMPConfig extends Partial<Config> {}

const MixPanelContext = createContext<Mixpanel | null>(null);

export const useMixpanel = () => {
	return useContext(MixPanelContext);
};

export const MixpanelProvider = ({
	children,
	token,
	config,
}: {
	children: React.ReactNode;
	token: string;
	config: IMPConfig;
}) => {
	mixpanel.init(token, config)

	return (
		<MixPanelContext.Provider value={mixpanel}>
			{children}
		</MixPanelContext.Provider>
	);
};
