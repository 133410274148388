import * as Response from './types/IResponse';
import * as Options from './types/IOptions';
import Axios from '../axios';
import stelace from '../stelace';
import IAPIChat from './types/IAPIChat';
import {
	IPage,
	IPageOptions,
	IPageOptionsAggregate,
	IPageResponse,
} from '../../../types/interfaces/IPage';
import API from '../../../utils/api';

export default class APIChat implements IAPIChat {
	private http: Axios;

	constructor(http: Axios) {
		this.http = http;
	}

	///////////////////
	///    CRUD     ///
	//////////////////

	public async create(
		topicId: string,
		content: string,
		options?: Options.IMessage,
	): Promise<Response.IMessage> {
		if (options?.userId)
			options.userId = Array.isArray(options.userId)
				? options.userId.join(',')
				: options.userId;
		// // // //console.log('OPTIONS USER ', options?.userId);
		let createdMessage = await stelace.messages.create({
			topicId,
			content,
			...options,
		});
		return createdMessage;
	}

	public async get(id: string | null): Promise<Response.IMessage | null> {
		let message: Response.IMessage | null = null;
		if (id) {
			message = await stelace.messages.read(id);
		}
		return message;
	}

	public async list(
		pageOptions: IPageOptions,
		options?: Options.IMessage,
	): Promise<IPage<Response.IMessage> | undefined> {
		let messages = await stelace.messages.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			order: pageOptions.order,
			orderBy: pageOptions.orderBy,
			...options,
		});

		return messages.length > 0
			? {
					items: messages,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async getStats(
		pageOptions: IPageOptionsAggregate<'conversationId', 'createdDate'>,
		options?: Options.IMessage,
	): Promise<
		| IPage<{
				count: number;
				min: string;
				max: string;
				groupBy: 'conversationId' | 'topicId';
				groupByValue: string;
		  }>
		| undefined
	> {
		let convs = await stelace.forward.get('/messages/stats', {
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			order: pageOptions.order,
			orderBy: pageOptions.orderBy,
			groupBy: pageOptions.groupBy,
			field: pageOptions.field,
			...options,
		});

		return convs.results.length > 0
			? {
					items: convs.results,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async listConversation(
		pageOptions: IPageOptions,
		options?: Options.IMessage,
	): Promise<IPage<Response.IMessage> | undefined> {
		let convs = await stelace.forward.get('/messages/last', {
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			order: pageOptions.order,
			orderBy: pageOptions.orderBy,
			...options,
		});

		return convs.results.length > 0
			? {
					items: convs.results,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async update(
		messageId: string,
		options?: Options.IMessage | undefined,
	): Promise<Response.IMessage> {
		return await stelace.messages.update(messageId, {
			...options,
		});
	}

	public async readMessages(messageIds: string[]): Promise<string[]> {
		return await stelace.forward.post(
			process.env.NEXT_PUBLIC_FRONTEND + '/api/readMessages',
			{
				messages: messageIds,
			},
		);
	}

	public async signal(
		data: {
			event: string;
			destination?: string;
			message?: any;
		},
		token?: string,
	) {
		const userToken = token || (await API.User.userToken());
		await this.http.post(
			'/signal',
			{
				event: data.event,
				destination: data.destination,
				message: data.message,
			},
			{
				headers: {
					Authorization: `Stelace-V1 apiKey=${process.env.NEXT_PUBLIC_BACKEND_PUBLIC_API_KEYS}, token=${userToken}`,
				},
			},
		);
	}
}
