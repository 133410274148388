import axios from 'axios';
import { IAddress, IAddressMangopay } from '../../../types/interfaces/IAddress';
import { IAdvProduct, IAdvTiming } from '../../../types/interfaces/IAdv';
import { IPageOptions, IPage } from '../../../types/interfaces/IPage';
import { TPlacement } from '../../../types/types/TAdv';
import API from '../../../utils/api';
import Axios from '../axios';
import { IProduct } from '../product/types/IResponse';
import stelace from '../stelace';
import { IUser } from '../user/types/IResponse';
import IAPITransactions from './types/IAPITransactions';
import * as Options from './types/IOptions';
import * as Response from './types/IResponse';

export default class APITransactions implements IAPITransactions {
	private http: Axios;

	constructor(http: Axios) {
		this.http = http;
	}

	public async preview(
		assetId: string,
		options?: Options.ITransaction,
	): Promise<Response.ITransaction> {
		return await stelace.transactions.preview({
			assetId,
			startDate: new Date().toISOString(),
			...options,
		});
	}

	public async get(transactionId: string): Promise<Response.ITransaction> {
		return await stelace.transactions.read(transactionId);
	}

	public async create(
		assetId: string,
		options?: Options.ITransaction,
	): Promise<Response.ITransaction> {
		return await stelace.transactions.create({
			assetId,
			startDate: new Date().toISOString(),
			...options,
		});
	}

	public async list(
		pageOptions: IPageOptions,
		options?: Options.ITransactionList,
	): Promise<IPage<Response.ITransaction> | undefined> {
		let transactions = await stelace.transactions.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			...options,
		});

		return transactions.length > 0
			? {
					items: transactions,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async transition(
		transactionId: string,
		options?: {
			name: string;
			data?: { cancellationReason?: Options.TCancellationReason };
		},
	): Promise<Response.ITransaction> {
		let transition = await stelace.transactions.createTransition(
			transactionId,
			options,
		);
		return transition;
	}

	public async update(
		transactionId: string,
		options?: Options.ITransaction,
	): Promise<Response.ITransaction> {
		return await stelace.transactions.update(transactionId, { ...options });
	}

	public async createAndPayin(
		assetId: string,
		options?: Options.ITransaction,
		notifies?: {
			createTransaction: () => void;
		},
	): Promise<{
		transaction: Response.ITransaction;
		payin: Response.IPayIn;
	}> {
		let transaction: Response.ITransaction =
			await stelace.transactions.create({
				assetId,
				startDate: new Date().toISOString(),
				...options,
			});
		notifies?.createTransaction();

		let payin = await stelace.forward.post(
			'/integrations/mangopay/payment',
			{
				method: 'Custom.payIn',
				args: [
					{
						transactionId: transaction.id,
						payment: {
							secureModeReturnUrl:
								process.env.NEXT_PUBLIC_FRONTEND +
								'/checkout/payment_processed',
						},
					},
				],
			},
		);

		return {
			transaction,
			payin,
		};
	}

	public async sponsorProduct(
		productIds: string[],
		advProduct: IAdvProduct,
		paymentMethod?: string,
		secureModeReturnUrl?: string,
	): Promise<Response.IPayIn> {
		const ip = await (await API.Transactions.getIp()).ipAddress;
		const browserData = {
			TimeZoneOffset: new Date().getTimezoneOffset(),
			ColorDepth: window.screen.colorDepth,
			ScreenHeight: window.screen.height,
			ScreenWidth: window.screen.width,
			UserAgent: navigator.userAgent,
		};

		let payin = await stelace.forward.post(
			'/integrations/mangopay/payment',
			{
				method: 'Custom.sponsorProduct',
				args: [
					{
						assetIds: productIds,
						advProduct: advProduct,
						ip,
						browserData,
						payment: {
							secureModeReturnUrl: secureModeReturnUrl
								? (process.env.NEXT_PUBLIC_FRONTEND || '') +
								  secureModeReturnUrl
								: process.env.NEXT_PUBLIC_FRONTEND +
								  '/checkout/payment_processed',
						},
						paymentMethod,
					},
				],
			},
		);

		for (const astId of productIds) {
			await API.Product.update(astId, {
				metadata: {
					sponsoredCategoryAdmin: !!advProduct.category,
					sponsoredHomeAdmin: !!advProduct.home,
				},
			});
		}

		return payin;
	}

	public async sponsorProfile(
		userId: string,
		// placement: Omit<TPlacement, 'unset' | 'category'>,
		timings: IAdvTiming,
		paymentMethod?: string,
	): Promise<Response.IPayIn> {
		const ip = await (await API.Transactions.getIp()).ipAddress;
		const browserData = {
			TimeZoneOffset: new Date().getTimezoneOffset(),
			ColorDepth: window.screen.colorDepth,
			ScreenHeight: window.screen.height,
			ScreenWidth: window.screen.width,
			UserAgent: navigator.userAgent,
		};

		let payin = await stelace.forward.post(
			'/integrations/mangopay/payment',
			{
				method: 'Custom.sponsorProfile',
				args: [
					{
						userId: userId,
						// placement: placement,
						timings: timings,
						ip,
						browserData,
						payment: {
							secureModeReturnUrl:
								process.env.NEXT_PUBLIC_FRONTEND +
								'/checkout/payment_processed',
						},
						paymentMethod,
					},
				],
			},
		);

		return payin;
	}

	public async createShipping(
		transactionId: string,
	): Promise<Response.IShippyproShip | undefined> {
		return await stelace.forward.post('/integrations/shippypro/request', {
			method: 'Ship',
			args: [
				{
					transactionId,
				},
			],
		});
	}

	public async getRatesShipping(
		asset: IProduct,
		toAddress: IAddressMangopay,
		carrier: string,
	): Promise<number | undefined> {
		const shippingRates = await stelace.forward.post(
			'/integrations/shippypro/request',
			{
				method: 'GetRates',
				args: [
					{
						asset: asset,
						carrier: carrier,
						toAddress: toAddress,
					},
				],
			},
		);

		return shippingRates.rate * 100;
	}

	public async getOrder(orderId: string): Promise<Response.IOrder> {
		return await stelace.orders.read(orderId);
	}

	public async getPayin(payinId: string): Promise<Response.IPayIn> {
		return await stelace.forward.post('/integrations/mangopay/request', {
			method: 'PayIns.get',
			args: [payinId],
		});
	}

	public async listOrders(
		pageOptions: IPageOptions,
		options?: Options.IOrderList | undefined,
	): Promise<IPage<Response.IOrder> | undefined> {
		let orders = await stelace.orders.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			...options,
		});

		return orders.length > 0
			? {
					items: orders,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async getIp(): Promise<{
		ipVersion: string;
		ipAddress: string;
		latitude: string;
		longitude: string;
		countryName: string;
		countryCode: string;
		timeZone: string;
		zipCode: string;
		cityName: string;
		regionName: string;
	}> {
		return (await axios.get('https://freeipapi.com/api/json')).data;
	}

	// private async transactionExist(
	// 	options: {
	// 		assetId: string;
	// 		ownerId?: string;
	// 		takerId?: string;
	// 	},
	// 	pageOptions?: IPageOptions,
	// ): Promise<Response.ITransaction | undefined> {
	// 	let transactionFinded = this.list(
	// 		pageOptions || { limit: 1, cursor: 1 },
	// 		{
	// 			assetId: options.assetId,
	// 			ownerId: options.ownerId,
	// 			takerId: options.takerId,
	// 		},
	// 	);
	// }
}
