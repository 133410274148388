import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useMixpanel } from '../components/MixPanel';
import API from '../utils/api';

export default function useAuth() {
	const { data: sessionInfo, isLoading: sessionInfoIsLoading } = useQuery(
		['session', 'info'],
		async () => await API.User.sessionInfo(),
	);
	const mixpanel = useMixpanel();

	const {
		data: user,
		isLoading: userIsLoading,
		refetch,
		isFetched,
	} = useQuery(
		['user', sessionInfo?.userId],
		async () => await API.User.get(sessionInfo?.userId || null),
		{
			enabled: !!sessionInfo,
		},
	);

	const isAuth = sessionInfo?.isAuthenticated && !sessionInfoIsLoading;

	useEffect(() => {
		if (isAuth && user?.id) {
			mixpanel?.identify(user.id);
			mixpanel?.people.set_once({
				$email: user.email,
				$first_name: user.firstname,
				$last_name: user.lastname,
				$avatar: user.metadata?.imgProfile,
				$created: user.createdDate,
				$phone:
					user.platformData?._private?.verified?.individualInfo
						?.phone || user.metadata?._private?.phoneNumber,
			});
		}
	}, []);

	return {
		isAuth,
		user: user,
		refetch,
		isFetched: isFetched,
		isLoading: userIsLoading || sessionInfoIsLoading,
	};
}
