let heading = {
	baseStyle: {
		fontWeight: 'semibold',
	},
	variants: {
		sectionTitle: {
			fontWeight: 'bold',
		},
	},
	defaultProps: {
		size: 'lg',
	},
};

export default heading;
