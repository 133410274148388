import {
	LinkBox,
	LinkOverlay,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	Link,
} from '@chakra-ui/react';
import IMenuRoutes from '../../types/interfaces/IMenuRoutes';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import IBaseProps from '../../types/interfaces/IBaseProps';
import MegaMenuChild from '../MegaMenuChild';

interface IProps extends IBaseProps {
	route: IMenuRoutes;
}
export default function MegaMenu({ route }: IProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const router = useRouter();

	return (
		<Menu key={route.path} isOpen={isOpen} offset={[0, 0]} isLazy>
			<MenuButton
				h={'100%'}
				py={3}
				px={3}
				display="flex"
				alignItems={'center'}
				_hover={{ bg: 'gray.50' }}
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={() => router.push(route.path)}
				fontWeight={'900'}
				color={'black'}
				borderTop={0}
				fontSize={{ md: '12px', xl: '14px' }}
			>
				{route.name}
			</MenuButton>

			{route.children && (
				<MenuList
					onMouseLeave={onClose}
					onMouseEnter={onOpen}
					onClick={onClose}
					borderTopRadius={0}
					py={0}
				>
					{route.children.map(subcat => (
						<LinkBox
							key={subcat.path}
							h={'100%'}
							display="flex"
							alignItems={'center'}
							_hover={{ bg: 'gray.50' }}
						>
							<NextLink href={subcat.path} passHref>
								<Link w={'100%'}>
									{subcat.children ? (
										<MenuItem
											fontWeight={'bold'}
											color={'blackAlpha.700'}
											p={0}
										>
											<MegaMenuChild
												route={subcat}
												key={subcat.path}
											></MegaMenuChild>
										</MenuItem>
									) : (
										<MenuItem
											fontWeight={'bold'}
											color={'blackAlpha.700'}
											px={4}
											py={2}
										>
											{subcat.name}
										</MenuItem>
									)}
								</Link>
							</NextLink>
						</LinkBox>
					))}
				</MenuList>
			)}
		</Menu>
	);
}
