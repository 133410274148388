let fonts = {
	fonts: {
		body: 'Intro',
		heading: 'Intro',
	},
	fontSizes: {
		xs: '0.75rem',
		
		xl: '1.09375rem',
		'2xl': '1.3125rem',
		'3xl': '1.640625rem',
		'4xl': '1.96875rem',
		'5xl': '2.625rem',
		'6xl': '3.28125rem',
		'7xl': '3.9375rem',
		'8xl': '5.25rem',
		'9xl': '7rem',
	},
};

export default fonts;
