import { Button, chakra } from '@chakra-ui/react';
import useLogout from '../../hooks/useLogout';
import IBaseProps from '../../types/interfaces/IBaseProps';

interface IProps extends IBaseProps {
	title?: string;
	variant?:
		| 'link'
		| 'outline'
		| (string & {})
		| 'ghost'
		| 'solid'
		| 'unstyled'
		| undefined;
}

function Logout(props: IProps) {
	const logoutService = useLogout();

	async function doLogout() {
		await logoutService.logout();
		document.location.reload();
	}

	return (
		<Button
			onClick={doLogout}
			variant={props.variant}
			className={props.className}
		>
			{props.title || 'Logout'}
		</Button>
	);
}

export default chakra(Logout);
