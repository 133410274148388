let button = {
	variants: {
		solid: {
			fontWeight: 800,
		},
		ghost: {
			_hover: {
				background: 'transparent',
			},
			_active: {
				background: 'transparent',
			},
		},
		outline: {
			background: 'white',
			bgGradient: 'none',
			color: `blackAlpha.800`,
			_hover: {
				background: 'blackAlpha.50',
			},
			_active: {
				background: 'transparent',
			},
		},
		noBorder: {
			background: 'transparent',
			bgGradient: 'none',
			color: `blackAlpha.800`,
			border: 'unset',
			fontSize: '2xl',
		},
		brandTwo: {
			bgColor: 'brandTwo.100',
			boxShadow: 'sm',
			border: '1px',
			borderColor: 'blackAlpha.300',
		},
		brandOne: {
			bgColor: 'brandOne.100',
			boxShadow: 'sm',
			border: '1px',
			borderColor: 'blackAlpha.300',
		},
	},
};

export default button;
