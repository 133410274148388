import {
	Divider,
	Link,
	HStack,
	LinkBox,
	Icon,
	LinkOverlay,
	Flex,
	Box,
	Image,
	Heading,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import useMenu from '../../hooks/useMenu';
import React from 'react';
import { BsFacebook, BsInstagram, BsTiktok } from 'react-icons/bs';

export default function SectionFooter() {
	const menuType = 'footer';
	const menu = useMenu(menuType);

	return (
		<>
			<Divider />
			<Flex
				justifyContent={'space-around'}
				align={{ base: 'start', md: 'center' }}
				flexFlow={{ base: 'column wrap', md: 'row wrap' }}
				w={'100%'}
				px={{ base: 2, md: 'unset' }}
			>
				<Box display={{ sm: 'none' }} ml={1}>
					<Heading mt={7} mb={5} size={'lg'}>
						BabyWanted
					</Heading>
				</Box>
				<Flex
					flexFlow={{ base: 'column wrap', sm: 'row wrap' }}
					maxH={{ base: '150px', sm: 'auto' }}
					w={{ base: '100%', lg: 'auto' }}
					justify={'center'}
					mt={{ sm: 4, lg: 0 }}
				>
					{menu.map(route => (
						<React.Fragment key={route.path}>
							<NextLink href={route.path} passHref>
								<Link
									variant={'footer'}
									mx={{ base: 2, sm: 4 }}
									my={{ base: 1, sm: 2 }}
								>
									{route.name}
								</Link>
							</NextLink>
						</React.Fragment>
					))}
				</Flex>
				<Flex
					mt={{ base: 3, md: 0 }}
					align={'center'}
					justify={'center'}
					w={{ base: '100%', md: 'auto' }}
				>
					<NextLink
						passHref
						href={
							'https://www.facebook.com/profile.php?id=100077416488176'
						}
					>
						<Link
							color={'blackAlpha.800'}
							mr={12}
							my={{ base: 2, md: 5 }}
							isExternal
							lineHeight={0}
						>
							<Icon as={BsFacebook} />
						</Link>
					</NextLink>
					<NextLink
						passHref
						href={'https://www.instagram.com/babywantedofficial/'}
					>
						<Link
							color={'blackAlpha.800'}
							mr={12}
							my={{ base: 0, md: 5 }}
							isExternal
							lineHeight={0}
						>
							<Icon as={BsInstagram} />
						</Link>
					</NextLink>
					<NextLink
						passHref
						href={'https://www.tiktok.com/@babywantedofficial'}
					>
						<Link
							color={'blackAlpha.800'}
							my={{ base: 0, md: 5 }}
							isExternal
							lineHeight={0}
						>
							<Icon as={BsTiktok} />
						</Link>
					</NextLink>
				</Flex>
			</Flex>
			<Flex justify={'center'} mt={{ base: 4, md: 0 }}>
				<Image
					src={'/images/logo_footer_24.png'}
					w={'230px'}
					alt={'logo_footer'}
				/>
			</Flex>
		</>
	);
}
