import * as Response from './types/IResponse';
import * as Options from './types/IOptions';
import Axios from '../axios';
import IAPIShop from './types/IAPIShop';
import stelace from '../stelace';
import { IPage, IPageOptions } from '../../../types/interfaces/IPage';
import { IProduct } from '../product/types/IResponse';
import { TEventName } from '../../../types/types/TEvent';
import { TPackagingSize } from '../../../types/types/TProduct';
import { TCarrier } from '../../../types/types/TShipping';
import IMerchantProduct from "../../../types/interfaces/IMerchantProduct";

export default class APIShop implements IAPIShop {
	private http: Axios;

	constructor(http: Axios) {
		this.http = http;
	}

	public async search(
		pageOptions: IPageOptions,
		options?: Options.ISearch,
	): Promise<IPage<IProduct> | undefined> {
		let searchedItems: IProduct[] = await stelace.search.results({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit,
			...options,
		});
		return searchedItems.length > 0
			? {
					items: searchedItems,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async searchBrand(brandQuery: string) {
		let customAttrReq = await (
			await this.listCustomAttributes({ cursor: 1 })
		).items;
		let customAttr = customAttrReq;
		let brandAttr = customAttr.find(
			(cAtt: { name: string; [otherCustomAttrParams: string]: any }) =>
				cAtt.name === 'brand',
		);
		let brandAttrId = brandAttr?.id;
		let brandsReq = await stelace.customAttributes.read(brandAttrId);
		let brands: string[] = brandsReq.listValues;

		return brands.filter((brand: string) =>
			brand
				.toLowerCase()
				.replaceAll(' ', '')
				.includes(brandQuery.toLowerCase().replaceAll(' ', '').trim()),
		);
	}

	public async getCategories(
		parentCategory?: string | null,
		options?: Options.ICategory,
	): Promise<Response.ICategory[]> {
		let categories = await stelace.categories.list();
		return categories.filter((cat: any) => {
			if (parentCategory === undefined) return true;
			if (parentCategory === null) if (cat.parentId === null) return true;
			if (parentCategory)
				if (cat.parentId === parentCategory) return true;
		});
	}

	public async getCategory(id: string): Promise<Response.ICategory> {
		return await stelace.categories.read(id);
	}

	public async getDocument(documentId: string): Promise<Response.IDocument> {
		return await stelace.documents.read(documentId);
	}

	public async listDocuments(
		pageOptions: IPageOptions,
		options: Options.IDocument,
	): Promise<IPage<Response.IDocument>> {
		let docs = await stelace.documents.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 15,
			...options,
		});
		return {
			items: docs,
			nextCursor: pageOptions.cursor + 1,
			pageNumber: pageOptions.cursor,
		};
	}

	public async deleteDocument(documentId: string): Promise<{ id: string }> {
		return await stelace.documents.remove(documentId);
	}

	public async listCustomAttributes(
		pageOptions: IPageOptions,
	): Promise<IPage<Response.ICustomAttribute>> {
		let customAttributes = await stelace.customAttributes.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 15,
		});
		return {
			items: customAttributes,
			nextCursor: pageOptions.cursor + 1,
			pageNumber: pageOptions.cursor,
		};
	}

	public async getConfig(): Promise<any> {
		return await stelace.config.read();
	}

	public async getPackagingSizeInfo(
		packagingSize: TPackagingSize,
		carrier?: TCarrier,
	): Promise<
		| {
				size: TPackagingSize;
				price: number;
				dimensions: {
					width: number;
					length: number;
					height: number;
					weigth: number;
				};
		  }
		| undefined
	> {
		const config = await this.getConfig();
		return config.custom.shipping[
			carrier || config.custom.defaultCarrier
		].pricing.find(
			(packSize: {
				size: TPackagingSize;
				price: number;
				dimensions: {
					width: number;
					length: number;
					height: number;
					weigth: number;
				};
			}) => packSize.size === packagingSize,
		);
	}

	public async getAssetType(
		assetTypeId: string,
	): Promise<Response.IAssetType> {
		return await stelace.assetTypes.read(assetTypeId);
	}

	public async getAllAssetType(): Promise<Response.IAssetType[]> {
		return await stelace.assetTypes.list();
	}

	public async listEntries(
		pageOptions: IPageOptions,
		options?: Options.IEntry | undefined,
	): Promise<IPage<Response.IEntry> | undefined> {
		let entries: Response.IEntry[] = await stelace.entries.list({
			page: pageOptions.cursor,
			nbResultsPerPage: pageOptions.limit || 10,
			order: pageOptions.order,
			orderBy: pageOptions.orderBy,
			...options,
		});

		return entries.length > 0
			? {
					items: entries,
					nextCursor: pageOptions.cursor + 1,
					pageNumber: pageOptions.cursor,
			  }
			: undefined;
	}

	public async getEntry(entryId: string): Promise<Response.IEntry> {
		return await stelace.entries.read(entryId);
	}

	public async newBrand(brand: string): Promise<Response.ICustomAttribute> {
		let newBrand = await stelace.forward.post(
			process.env.NEXT_PUBLIC_FRONTEND + '/api/createNewBrand',
			{
				brand,
			},
		);

		return newBrand;
	}

	public async createEvent(
		name: TEventName,
		options?: Options.IEvent,
	): Promise<Response.IEvent> {
		return await stelace.events.create({ type: name, ...options });
	}
}
