import { createContext, useContext } from 'react';
import axios from 'axios';

interface IACConfig {
	accountId?: string;
}
interface IACOptions {
	email?: string;
	eventData?: unknown;
}

const ActiveCampaignContext = createContext<{
	acTrack: (event: string, options?: IACOptions) => void;
} | null>(null);

export const useActiveCampaign = () => {
	return useContext(ActiveCampaignContext);
};

export const ActiveCampaignProvider = ({
	children,
	config,
}: {
	children: React.ReactNode;
	config?: IACConfig;
}) => {
	const acTrack = async (event: string, options?: IACOptions) => {
		try {
			await axios.post(
				`${process.env.NEXT_PUBLIC_FRONTEND}/api/ac-events`,
				{
					event,
					eventdata: options?.eventData,
					email: options?.email,
				},
			);
		} catch (error) {
			console.error('ActiveCampaign tracking error: ', error);
		}
	};

	return (
		<ActiveCampaignContext.Provider value={{ acTrack }}>
			{children}
		</ActiveCampaignContext.Provider>
	);
};
